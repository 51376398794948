
import { Vue, Options } from "vue-class-component";
import { store } from "@/store";
import router from "@/router";
import { ApplicationError } from "@/api-client/api";
import { Form, Field, ErrorMessage } from "vee-validate";
import Spinner from "@/components/spinner/Spinner.vue";

@Options({
  components: { Form, Field, ErrorMessage, Spinner },
})
export default class ForgotPasswordPage extends Vue {
  email = "";
  password = "";
  confirmPassword = "";
  showPassword = false;
  showConfirmPassword = false;
  loading = false
  errors: Array<string> | null = null;
  done = false;
  code = "";
  regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])(.{8,})$/

  created() {
    var queryParams = router.currentRoute.value.query as any;
    if (queryParams.code != null) {
      this.code = unescape(queryParams.code) as string;
      debugger;
    }
  }

  togglePassword(fieldName: string) {
    let field;
    if (fieldName === "password") {
      field = document.getElementById("forgot-password") as HTMLInputElement;
      this.showPassword = !this.showPassword;

      if (this.showPassword) {
        field.type = "text";
      } else {
        field.type = "password";
      }
    } else {
      field = document.getElementById("forgot-confirm-password") as HTMLInputElement;
      this.showConfirmPassword = !this.showConfirmPassword;

      if (this.showConfirmPassword) {
        field.type = "text";
      } else {
        field.type = "password";
      }
    }
  }

  async forgotPassword() {
    if (!this.done) {
      this.loading = true;
      await store
        .dispatch("auth/forgotPassword", { email: this.email })
        .then((res: any) => {

          this.done = true;
          this.loading = false
        })
        .catch((error: any) => {
          this.loading = false;
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
        });
        this.loading = false
    } else {
      this.done = false;
      this.loading = false
    }
  }

  resetPasswordComplete() {
    this.errors = null;
    if (this.password != this.confirmPassword) {
      this.errors = ["Passwords do not match"];
    } else {
      this.loading = true
      store
        .dispatch("auth/resetPasswordComplete", {
          email: this.email,
          token: this.code,
          password: this.password,
        })
        .then((res: any) => {
          store
            .dispatch("auth/login", {
              email: this.email,
              password: this.password,
            })
            .then((res) => {
              this.$notify({ type: "success", text: 'Password reset successful.', ignoreDuplicates: true });
              router.push({ name: "Home", params: {lang: this.$route.params.lang} });
              this.loading = false
            })
        })
        .catch((error: any) => {
          this.loading = false;
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
          });
        });
        this.loading = false
    }
  }
}
