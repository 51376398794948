
import { mixins, Options, Vue } from "vue-class-component";
import Spinner from "@/components/spinner/Spinner.vue";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import HeadingBanner from "@/components/banners/HeadingBanner.vue";
import CartProductTile from "@/components/checkout-process/cart/CartProductTile.vue";
import CartSummary from "@/components/checkout-process/cart/CartSummary.vue";
// import CheckoutBreadcrumbs from "@/components/checkout-process/CheckoutBreadcrumbs.vue";
import CartDeliveryOptions from "@/components/checkout-process/cart/CartDeliveryOptions.vue";
import { CartItems, Carts } from "@/network/api";
import { CountryViewModel, DeliveryType, OrderCartDeliveryOptionViewModel, OrderCartViewModel, OrderStatus } from "@/api-client";
import { Currency, FormatDate, QuantityPerItem } from "@/mixins/utilities";
import BasciPromptModal from '@/components/misc/BasicPromptModal.vue'
import { store } from "@/store";
import StatementBanner from '@/components/banners/StatementBanner.vue'
import { useMeta } from 'vue-meta'
import { SetMetaData } from "@/mixins/utilities";
import CheckoutBreadcrumbs from "@/components/checkout-process/CheckoutBreadcrumbs.vue";

@Options({
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: { Spinner, HeadingBanner, Breadcrumbs, CartProductTile, CartSummary, CartDeliveryOptions, BasciPromptModal, StatementBanner, CheckoutBreadcrumbs },
})
export default class QuotePage extends mixins(Currency, QuantityPerItem,SetMetaData, FormatDate) {
  id = ''
  cart: OrderCartViewModel = {
    id: '',
    items: [],
    countryId: '',
    isGift: false,
    deliveryTypeId: DeliveryType.Standard,
    orderStatusId: OrderStatus.Cart,
    currencyId: '',
    currencyPricingMultiplyer: 1,
    requiresCustomerApproval: true,
    orderDocuments: [],
    isQuote: false,
    marketingOptIn: false,
    additionalCostsConvertedTotal: 0,
    referenceNumber: '',
    deliveryAddress: {
      id: '',
      addressLine1:	'',
      area:	'',
      country:	'',
      postalCode:	''
    },
    billingAddress: {
      id: '',
      addressLine1:	'',
      area:	'',
      country:	'',
      postalCode:	''
    },
    customerContact: {
      id: '',
      name: '',
      email: '',
      phoneNumber: ''
    },
    accessKey: '',
    allowedPaymentProviders:  [],
    paymentsTotal: 0,
    paymentsConvertedTotal: 0,
    customerContacts: []
  }
  summaryTotal: Array<number> = [];
  deliveryOptions: Array<OrderCartDeliveryOptionViewModel> = [];
  deliveryType: OrderCartDeliveryOptionViewModel = {
    deliveryTypeId:	DeliveryType.Standard,
    deliveryTypeName:	'',
    deliveryDays:	0,
    productionDays:	0,
    deliveryCost:	0.00,
    deliveryDelayDays: 0,
    productionDelayDays: 0
  }
  expressDelivery = false;
  productIdToRemove: null | string = null
  loading = false;
  accessKey: string | undefined = undefined
  showCartSummary = true;

  get totalPrice() {
    let total = 0;
    let sum = this.summaryTotal.reduce((acc:any, curr:any) => {
    return acc + curr
    }, total)
    return sum;
  }

  get cartId() {
    return store.getters["checkout/cartId"];
  }

  get shareLink() {
    if(this.cart.id && (this.cart.orderStatusId == 'QuoteProvided')) {
      let routeData = this.$router.resolve({name: 'Quote', params: {id: this.cart.id, lang: this.$route.params.lang }});
      return `${window.location.origin}${routeData.href}`
    } else if(this.cart.id) {
      let routeData = this.$router.resolve({name: 'Cart', params: {id: this.cart.id, accessKey: this.cart.accessKey, lang: this.$route.params.lang }});
      return `${window.location.origin}${routeData.href}`
    }
    return ''
  }

  get cartCurrency() {
    return this.currency();
  }

  created() {
    const {meta} = useMeta({})

    if(this.$route.query.accessKey) {
      this.accessKey = this.$route.query.accessKey as string
    }
  
    this.setMetaData(meta, {}, '', 'Quote | ')

    this.loadInitialData()
  }

  handleRemoveId(id: string, action = "save") {
    if(action === "save") {
      this.productIdToRemove = id;
    }   

    if(action === "clear") {        
      this.productIdToRemove = null;
    }
  }

  removeProduct() {
    if(this.productIdToRemove) {
      this.loading = true
      CartItems.cartItemsIdDelete(this.productIdToRemove, this.accessKey).then((res) => {
        if(res.data.succeeded) {
          window.dataLayer?.push({'event': 'remove_from_cart'}) // GTM Event
          this.loadInitialData()
        this.productIdToRemove = null;
        }
        this.loading = false
      })
      .catch((error) => {
        console.log(error)
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
    }
  }

  updateDelivery(selectedDeliveryType: OrderCartDeliveryOptionViewModel, selectedCountry: CountryViewModel) {
    if(this.cart.id) {
      
      this.deliveryType = selectedDeliveryType;
      this.cart.deliveryTypeId = this.deliveryType.deliveryTypeId;
      this.cart.countryId = selectedCountry.id;
      this.cart.currencyId = selectedCountry.currency.id; 
      
      if(this.cart.id) {
        this.updateCart();
      }
    }
    
  }

  updateDeliveryOptions(id: string) {  
    if(this.cart?.orderStatusId == 'QuoteProvided') {
      this.getDeliveryOptions(id);
    }
  }

  copyLink() {
    // Write to the clipboard
    if(this.shareLink) {
      let isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
      if (!isMobile) {
        navigator.clipboard.writeText(this.shareLink);
        this.$notify({ type: "success", text: "The quote link has been copied to your clipboard." });
      }
      else {
        const sharing = async () => {
          if (navigator.share) {
            await navigator.share({
              title: 'CLIFTON | Truly Custom Clothing',
              text: 'View my CLIFTON quote!',
              url: this.shareLink
            })
            .then(() => {
                this.$notify({ type: "success", text: "Quote link copied to clipboard." });
              }
            )
            .catch(err => {
                if (err.toString().indexOf("cancel")) {
                  this.$notify({ type: "warning", text: "Copy of quote link cancelled." });
                }
                else {
                  this.$notify({ type: "error", text: `Error: ${err}` });
                }
              }
            );
          }
          else {
            navigator.clipboard.writeText(this.shareLink);
            this.$notify({ type: "success", text: "The quote link has been copied to your clipboard." });
          }
        }

        sharing();
      }
    }
  }

  async updateCart() {
    if(this.cart?.orderStatusId === "QuoteProvided") {
      this.loading = true
    await Carts.cartsUpdateCartIdPut(this.id, this.accessKey, this.cart)
    .then((res) => {
      this.loading = false
    })
    .catch((error) => {
console.log(error)
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
    }
  }

  handleCustomerResponse(response:any) {
    window.scrollTo(0, 0);
    this.loading = true
    Carts.cartsRespondToOrderQuotePut(this.id, response.accepted, response.message || undefined)
    .then(async (res) => {
        if (res.data.succeeded) {
          if(response.accepted) {
            await store.dispatch("checkout/refreshCart", {
              id: this.id,
              accessKey: this.accessKey
            })
            .then(() => {
              this.$router.push({ name: "Checkout", params: { id: this.id, lang: this.$route.params.lang } });
            })
          } else {
            this.$notify({ type: "success", text: "Response sent successfully." });
            this.$router.push({ name: "Home", params: {lang: this.$route.params.lang} });
          }        
        }
        this.loading = false;
      })
      .catch((error) => {
        console.log(error)
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }

  async loadInitialData() {
    console.log('cart id', this.id);
    if(this.id) {
      this.loading = true;     
      await Carts.cartsGetCartGet(this.id, this.accessKey)
      .then(async (res) => {
        if (res.data.succeeded) {
          console.log('in load', res);
          
          this.cart = res.data.resultData as OrderCartViewModel;
          this.summaryTotal = [];
          
          this.cart.items.forEach((product: any) => {
            this.summaryTotal.push(product.price * this.quantityPerItem(product));
          });
          if(this.cart.orderStatusId === 'QuoteProvided') {
            await this.getDeliveryOptions(this.cart.countryId)
          }
          
        }
        this.loading = false;
      })
      .catch((error) => {
console.log(error)
        this.loading = false;
        if(error.response.status == 401) {
            this.$notify({ type: "error", text: 'Please log in to the relevant account to view this page.', ignoreDuplicates: true, duration: -1 });
            this.$router.push({ name: 'Login', params: {lang: this.$route.params.lang}, query: { returnUrl: this.$route.fullPath, clear: 'true' }});
          } else {
            let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          if(error.friendlyMessage == 'You do not have access to this order') {
            this.$notify({ type: "error", text: 'Please log in to the relevant account to view this page.', ignoreDuplicates: true, duration: -1 });
            this.$router.push({ name: 'Login', params: {lang: this.$route.params.lang}, query: { returnUrl: this.$route.fullPath, clear: 'true' }});
          }
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
          }
      });
    }   
  }


  async getDeliveryOptions(id: string) {
    if(this.id) {
      await Carts.cartsGetCartDeliveryOptionsIdGet(this.id, id, this.accessKey)
      .then((res) => {
        if (res.data.succeeded) {
          this.deliveryOptions = res.data.resultData as Array<OrderCartDeliveryOptionViewModel>;
          console.log("delivery options loaded cartpage", this.deliveryOptions);
        }
      })
      .catch((error) => {
console.log(error)
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
    }  
  }
}
