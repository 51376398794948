
import { mixins, Options, Vue } from "vue-class-component";
import ProductDesigner from "@/features/product-builder/ProductDesigner.vue";
import { useMeta } from 'vue-meta'
import { SetMetaData } from "@/mixins/utilities";
import { IReCaptchaComposition, VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3'

@Options({
  components: {
    ProductDesigner,
  },
})
export default class DesignerPage extends mixins(SetMetaData) {
  originSlug = ''
   recapthcaObject: IReCaptchaComposition | undefined = undefined

  created() {
    const {meta} = useMeta({})
  
    this.setMetaData(meta, {}, '', 'Designer | ')

    if(!Object.keys(this.$route.query).length) {
      this.$router.push({ name: 'AllProductSelector', params: {lang: this.$route.params.lang} })
    } else {
      if(this.$route.query.origin) {
        this.originSlug = this.$route.query.origin as string
      }
    }

    this.recapthcaObject = useReCaptcha() as IReCaptchaComposition
  }

  mounted() {
    this.hideRecaptcha()
  }

  async hideRecaptcha () {  
    if(this.recapthcaObject) {
      // Wait until recaptcha has been loaded.
      await this.recapthcaObject.recaptchaLoaded()

      // Hide recaptcha has been loaded.
      const reCapthca = document.querySelector('.grecaptcha-badge') as any;
      if(reCapthca) {
        reCapthca.style.display = `none`;
      }
    }
  }
}
