
import { mixins, Options } from "vue-class-component";
import { Categories, Products } from "@/network/api";
import StatementBanner from "@/components/banners/StatementBanner.vue";
import ProductSelectorCategoryTile from "@/components/product-selector/ProductSelectorCategoryTile.vue";
// import ProductSelectorProductTile from "@/components/product-selector/ProductSelectorProductTile.vue";
import { CategoryListViewModel, CategoryListViewModelPaginatedList, CategoryViewModel, ProductListViewModel, ProductListViewModelPaginatedList, ProductListViewModelPaginatedListApplicationResultResultData, ProductTagViewModel } from "@/api-client";
import Spinner from "@/components/spinner/Spinner.vue";
import utilities from "@/composables/utilities";
import { HasAdminRoles, IsLoggedIn, LoadImage, SetMetaData, UploadPath } from "@/mixins/utilities";
import { store } from "@/store";
import { useMeta } from "vue-meta";
import ProductTile from "@/components/products/ProductTile.vue";

@Options({
  components: {
    StatementBanner,
    ProductSelectorCategoryTile,
    // ProductSelectorProductTile,
    Spinner,
    ProductTile,
  },
  props: { slug: { default: [] } },
  beforeRouteUpdate(to, from) {
    this.clearArrays();
    if(!to.query.store) {
      this.storeId = "";
    } else {
      this.storeId = to.query.store;
    }

    if(!to.query.adminView) {
      this.isAdminStoreView = false;
    } else if(to.query.adminView == 'true') {
      this.isAdminStoreView = true;
    }
  },
  beforeRouteLeave(to, from) {
    this.clearArrays();
  },
})
export default class CategoriesPage extends mixins(LoadImage, UploadPath, SetMetaData, IsLoggedIn, HasAdminRoles) {
  categories: Array<CategoryListViewModel> = [];
  categoryPages: CategoryListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 12,
  };
  category: CategoryViewModel = {
    id: "",
    name: "",
    parentSlug: "",
    slug: "",
    fullSlug: "",
    seoTitle: "",
    seoKeywords: "",
    seoDescription: "",
    isFeatured: false,
    isDisabled: false,
    hasProducts: false,
    blurb: "",
    thumbnailImages: [],
    bannerImages: [],
    parentCategories: [],
  };
  title = "";
  bannerImage = "";
  slug: Array<string> = [];
  joinedSlug = "";
  filters: Array<ProductTagViewModel> = [];
  categoryProducts: Array<ProductListViewModel> = [];
  productPages: ProductListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 12,
  };
  singleCategoryPages: CategoryListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 12,
  };
  selectedFilters: Array<any> = [];
  searchTerm = "";
  pageIndex = 1;
  loading = true;
  searchLoading = false;
  debounce: any = null;
  originalBanner: any = {};
  storeId = "";
  isAdminStoreView = false

  created() {
    this.joinedSlug = utilities.joinSlug(this.slug);

    if (this.joinedSlug && this.joinedSlug !== "all") {
      this.loadNestedCategories(this.joinedSlug);
    } else {
      this.loadAllCategories();
    }

    if (this.$route.query.quote) {
      let roles = store.getters["user/role"];

      if (!this.isLoggedIn || !this.hasAdminRoles(roles)) {
        this.$router.push({ name: "Login", params: {lang: this.$route.params.lang}, query: { returnUrl: this.$route.fullPath, clear: "true" } });
      } else {
        let quoteId = this.$route.query.quote;
        store.dispatch("checkout/getCart", {
          quoteId: quoteId,
        });
      }
    }

    if (this.$route.query.store && this.$route.query.store != '0') {
      this.storeId = this.$route.query.store as string;

      if(this.$route.query.adminView == 'true') {
        this.isAdminStoreView = true;
      }
    }

    this.$watch("joinedSlug", () => {
      // console.log("joined", this.joinedSlug);

      if (this.joinedSlug && this.joinedSlug !== "all") {
        this.loadNestedCategories(this.joinedSlug);
      } else {
        this.loadAllCategories();
      }
    });
  }

  toDesigner(product: any) {
    this.$router.push({ name: "Designer", params: {lang: this.$route.params.lang}, query: { product: product.id, origin: this.joinedSlug || undefined, store: this.storeId || undefined, adminView: this.isAdminStoreView ? 'true' : undefined } });
  }

  toCategory(slug: string) {
    this.clearArrays();
    this.$router.push({ name: "ProductSelector", params: { slug: slug, lang: this.$route.params.lang }, query: { store: this.storeId || undefined, adminView: this.isAdminStoreView ? 'true' : undefined } });
  }

  isScrolledIntoView(el: any) {
    let rect = el.getBoundingClientRect() as DOMRect;
    let elemTop = rect.top;
    let elemBottom = rect.bottom;
    let isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
  }

  scroll() {
    window.onscroll = () => {
      let allRefs = this.$refs as any;
      let scrolledTo = allRefs.bottom;

      if (scrolledTo && this.isScrolledIntoView(scrolledTo)) {
        this.loadMoreItems();
      }
    };
  }

  mounted() {
    this.scroll();

    const { meta } = useMeta({});
    meta.title = "Product Selector | CLIFTON | Truly Custom Clothing";

    this.$watch("category.id", () => {
      if (!this.category.id) {
        meta.title = "Product Selector | CLIFTON | Truly Custom Clothing";
      }
      this.setMetaData(meta, this.category, "id", "Product Selector | ");
    });
  }

  clearArrays() {
    //empty all arrays for lazy loading
    this.category = {
      id: "",
      name: "",
      parentSlug: "",
      slug: "",
      fullSlug: "",
      seoTitle: "",
      seoKeywords: "",
      seoDescription: "",
      isFeatured: false,
      isDisabled: false,
      hasProducts: false,
      blurb: "",
      thumbnailImages: [],
      bannerImages: [],
      parentCategories: [],
    };
    this.pageIndex = 1;
    (this.searchTerm = ""), (this.selectedFilters = []);
    this.categories = [];
    this.categoryProducts = [];
    this.loading = true;
    this.categoryPages = {
      hasNextPage: false,
      hasPreviousPage: false,
      items: [],
      pageIndex: 1,
      totalCount: 1,
      totalPages: 1,
      pageSize: 12,
    };
    this.productPages = {
      hasNextPage: false,
      hasPreviousPage: false,
      items: [],
      pageIndex: 1,
      totalCount: 1,
      totalPages: 1,
      pageSize: 12,
    };
    this.singleCategoryPages = {
      hasNextPage: false,
      hasPreviousPage: false,
      items: [],
      pageIndex: 1,
      totalCount: 1,
      totalPages: 1,
      pageSize: 12,
    };
    clearTimeout(this.debounce);
    this.originalBanner = {};
  }

  imagePath(item: any) {
    if (item.thumbnailImages.length > 0) {
      return `${this.uploadPath}/${item.thumbnailImages[0].imageUrl}`;
    }
    return ``;
  }

  updated() {
    if (this.slug) {
      this.joinedSlug = utilities.joinSlug(this.slug);
    } else {
      this.joinedSlug = "";
    }
  }

  getProducts(pageIndex = 1, pageSize = 12) {
    Products.productsParentCategorySlugGet(pageIndex, pageSize, this.joinedSlug, this.selectedFilters, this.searchTerm)
      .then((res) => {
        if (res.data.succeeded) {
          this.productPages = res.data.resultData as ProductListViewModelPaginatedListApplicationResultResultData;
          this.productPages.items.map((item) => {
            this.categoryProducts.push(item);
          });
        }

        this.loading = false;
        clearTimeout(this.debounce);
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
        clearTimeout(this.debounce);
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }

  handleFilterChange(filters: Array<any> = [], search = "") {
    console.log("filterchange");
    this.loading = true;
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.pageIndex = 1;
      this.selectedFilters = filters;
      this.searchTerm = search;
      this.categoryProducts = [];
      this.getProducts(this.pageIndex);
    }, 400);
  }

  loadAllCategories(pageIndex = 1, pageSize = 12) {
    // console.log("loading all cats");
    this.loadImage("/img/categories-image-thin-s.jpg")
      .then((img: any) => (this.originalBanner = img))
      .catch((err) => console.error(err));

    Categories.categoriesGet(pageIndex, pageSize)
      .then((res) => {
        if (res.data.succeeded) {
          this.categoryPages = res.data.resultData as CategoryListViewModelPaginatedList;
          this.categoryPages.items.map((item: CategoryListViewModel) => {
            this.categories.push(item);
          });
          // console.log("pushed cats", this.categories);

          this.loading = false;
        }
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }

  loadSingleCategory(pageIndex = 1, pageSize = 12, slug: string) {
    Categories.categoriesParentCategorySlugGet(pageIndex, pageSize, slug)
      .then((res) => {
        if (res.data.succeeded) {
          this.singleCategoryPages = res.data.resultData as CategoryListViewModelPaginatedList;
          this.singleCategoryPages.items.map((item) => {
            this.categories.push(item);
          });
          this.loading = false;
          console.log("single cat items", this.categories);
        }
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }

  loadNestedCategories(slug: string) {
    this.loading = true;
    Categories.categoriesSingleCategorySlugGet(slug)
      .then((res) => {
        if (res.data.succeeded) {
          this.category = res.data.resultData as CategoryViewModel;

          if (this.category.name) {
            this.title = this.category.name;
          }

          if (this.category.hasProducts) {
            // console.log("loading single prods");
            this.getProducts(this.pageIndex);
          }

          if (slug && !this.category.hasProducts) {
            // console.log("loading single cate");
            this.loadSingleCategory(this.pageIndex, 12, slug);
          }
        }
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }

  loadMoreItems() {
    if (this.loading === false && (this.categoryPages.hasNextPage === true || this.productPages.hasNextPage === true || this.singleCategoryPages.hasNextPage === true)) {
      this.loading = true;
      this.pageIndex += 1;
      console.log("adding 1 to index");

      if (this.joinedSlug && this.joinedSlug !== "all") {
        this.loadNestedCategories(this.joinedSlug);
      } else {
        this.loadAllCategories(this.pageIndex);
      }
    }
  }
}
