
import { HasAdminRoles, IsLoggedIn } from "@/mixins/utilities";
import { mixins, Options } from "vue-class-component";
import CreateEditCustomStore from "@/components/profile/custom-stores/CreateEditCustomStore.vue";
import { store } from "@/store";

@Options({
  components: { CreateEditCustomStore },
})
export default class CustomStoreAdminStorePage extends mixins(IsLoggedIn, HasAdminRoles) {
  isAdminView = false;

  get roles() {
    return store.getters["user/role"];
  }

  created() {
    if (!this.isLoggedIn || !this.hasAdminRoles(this.roles)) {
      this.$router.push({ name: "Login", params: {lang: this.$route.params.lang}, query: { returnUrl: this.$route.fullPath, clear: "true" } });
    } else if(this.hasAdminRoles(this.roles)) {
      this.isAdminView = true
    }
  }

  toOMS() {
    let link = `${process.env.VUE_APP_ROOT_ADMIN}/online-stores/edit-online-store/${this.$route.params?.id}`;
    window.open(link, "_self");
  }
}
