
import { mixins, Options } from "vue-class-component";
import HeadingBanner from "@/components/banners/HeadingBanner.vue";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import { useMeta } from "vue-meta";
import { SetMetaData } from "@/mixins/utilities";
import { Form, Field, ErrorMessage } from "vee-validate";
import { Orders } from "@/network/api";
import { OrderHistoryViewModel, OrderPublicStatus } from "@/api-client";
import Spinner from "@/components/spinner/Spinner.vue";
import OrderListTile from "@/components/profile/order-history/OrderListTile.vue";
import OrderDetails from "@/components/profile/order-history/OrderDetails.vue";
import OrderInvoiceList from "@/components/profile/order-history/OrderInvoiceList.vue";

@Options({
  components: { HeadingBanner, Breadcrumbs, SetMetaData, Form, Field, ErrorMessage, Spinner, OrderListTile, OrderDetails, OrderInvoiceList },
})
export default class CheckOrderStatusPage extends mixins(SetMetaData) {
  loading = false;
  orderReference = "";
  postalCode = "";
  order: OrderHistoryViewModel = {
    id: "",
    referenceNumber: "",
    orderPublicStatusId: OrderPublicStatus.Processing,
    orderPlacementDate: "",
    expectedDeliveryDate: "",
    showOrderPackApprovalLink: false,
    showAssetResupplyLink: false,
    showOutstandingPaymentLink: false,
    marketingOptIn: false,
    additionalCostsConvertedTotal: 0,
    currency: {
      id: "",
      name: "",
      code: "",
      symbol: "",
      pricingMultiplier: 1,
      decimalPlaces: 2,
    },
    orderTotal: 0,
    orderTotalIncludingVoucher: 0,
    orderItems: [],
    deliveryTotal: 0,
    adhocTotal: 0,
    orderInvoices: [],
    customerContacts: [],
    paymentsConvertedTotal: 0,
    paymentsTotal: 0
  };
  errors: Array<string> = [];
  showDetails = false;
  showInvoice = false;
  wasRegisteredUser = false;

  created() {
    const { meta } = useMeta({});

    this.setMetaData(meta, {}, "", `Check Order Status | `);

    this.errors = [];

    if (this.$route.query.order) {
      this.orderReference = this.$route.query.order as string;
    }

    if (this.$route.query.postal) {
      this.postalCode = this.$route.query.postal as string;
    }

    if (this.orderReference && this.postalCode) {
      this.loadOrder();
    }
  }

  closeShowDetails() {
    this.showDetails = false;
    this.$router.replace({ name: "CheckOrderStatus", params: { lang: this.$route.params.lang }, query: { order: this.orderReference, postal: this.postalCode } });
  }

  closeInvoices() {
    this.showInvoice = false;
    this.$router.replace({ name: "CheckOrderStatus", params: { lang: this.$route.params.lang }, query: { order: this.orderReference, postal: this.postalCode } });
  }

  getInvoiceDetails() {
    this.showInvoice = true;
    this.$router.replace({ name: "CheckOrderStatus", params: { lang: this.$route.params.lang }, query: { order: this.orderReference, postal: this.postalCode, invoicelist: this.order.id } });
  }

  handleFindAnotherOrder() {
    this.$router.replace({ name: "CheckOrderStatus", params: { lang: this.$route.params.lang } });
    this.showInvoice = false;
    this.showDetails = false;
    this.orderReference = "";
    this.postalCode = "";
    this.errors = [];
    this.wasRegisteredUser = false;
    this.order = {
      id: "",
      referenceNumber: "",
      orderPublicStatusId: OrderPublicStatus.Processing,
      orderPlacementDate: "",
      expectedDeliveryDate: "",
      showOrderPackApprovalLink: false,
      showAssetResupplyLink: false,
      showOutstandingPaymentLink: false,
      marketingOptIn: false,
      additionalCostsConvertedTotal: 0,
      currency: {
        id: "",
        name: "",
        code: "",
        symbol: "",
        pricingMultiplier: 1,
        decimalPlaces: 2,
      },
      orderTotal: 0,
      orderTotalIncludingVoucher: 0,
      orderItems: [],
      deliveryTotal: 0,
      adhocTotal: 0,
      orderInvoices: [],
      customerContacts: [],
      paymentsConvertedTotal: 0,
      paymentsTotal: 0
    };
  }

  loadOrder() {
    this.errors = [];
    this.wasRegisteredUser = false;

    if (this.orderReference && this.postalCode) {
      this.loading = true;

      this.orderReference = this.orderReference.trim();
      this.postalCode = this.postalCode.trim();

      Orders.ordersGetOrderByReferenceNumberReferenceNumberGet(this.orderReference, this.postalCode)
        .then((res) => {
          if (res.data.succeeded) {
            this.order = res.data.resultData as OrderHistoryViewModel;
            this.$router.replace({ name: "CheckOrderStatus", params: { lang: this.$route.params.lang }, query: { order: this.orderReference, postal: this.postalCode, details: this.$route.query.details, invoicelist: this.$route.query.invoicelist, invoice: this.$route.query.invoice } });
            // this.orderReference = '';
            // this.postalCode = '';
            if (this.$route.query.details) {
              this.showDetails = true;
            } else if (this.$route.query.invoicelist || this.$route.query.invoice) {
              this.showInvoice = true;
            }
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            // this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
            this.errors.push(error.friendlyMessage);

            if(error.friendlyMessage == 'This order is already visible in your order history. Go check it out!') {
              this.wasRegisteredUser = true;
            }
          });
          this.$router.replace({ name: "CheckOrderStatus", params: { lang: this.$route.params.lang } });
        });
    }
  }
}
