
import { mixins, Options } from "vue-class-component";
import Spinner from "@/components/spinner/Spinner.vue";
import HeadingBanner from "@/components/banners/HeadingBanner.vue";
import OrderSummary from "@/components/checkout-process/checkout/OrderSummary.vue";
import { CalculatePrice, FormatDate, IsLoggedIn, QuantityPerItem, ThousandSeparator } from "@/mixins/utilities";
import { loadStripe } from "@stripe/stripe-js";
import { useRoute } from "vue-router";
import { store } from "@/store";
import { Carts, Orders, Stores } from "@/network/api";
import { OrderCartItemQuantityViewModel, OrderHistoryViewModel, OrderPublicStatus, StoreOrderCartViewModel, StoreStatus, StoreViewModel } from "@/api-client";
import CompleteOrderSummary from "@/components/checkout-process/payment-complete/CompleteOrderSummary.vue";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import { useMeta } from 'vue-meta'
import { SetMetaData } from "@/mixins/utilities";
import { TrackPurchase } from "@/mixins/gtag";
import { FacebookPurchaseEvent } from "@/seo/facebook-pixel-helper";

@Options({
  components: { Spinner, HeadingBanner, OrderSummary, CompleteOrderSummary, Breadcrumbs, SetMetaData },
  props: {
    id: {
      type: String,
      required: true,
    }
  },
})
export default class CustomStoreCustomerPaymentCompletePage extends mixins(IsLoggedIn, QuantityPerItem, SetMetaData, FormatDate, ThousandSeparator, CalculatePrice, TrackPurchase, FacebookPurchaseEvent) {
  id: any = "";
  stripeKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
  stripe: any;
  stripeLoaded: any;
  storeCustomerOrder: StoreOrderCartViewModel = {
    id: "",
    referenceNumber: '',
    storeId: "",
    name: "",
    email: "",
    phoneNumber: "",
    items: []
  };
  storeDetails: StoreViewModel = {
    id: "",
    startDate: "",
    endDate: "",
    title: "",
    deliveryFeePerCustomer: 0,
    storeStatusId: StoreStatus.Approved,
    countryId: "",
    countryName: "",
    currency: {
      id: "",
      name: "",
      code: "USD",
      symbol: "",
      pricingMultiplier: 0,
      decimalPlaces: 2,
    },
    referenceNumber: ""
  };
  loading = false;
  orderLoaded = false
  messages: Array<string> = [];
  tryCount = 0
  // accessKey: string | undefined = undefined
  clientSecret = "";
  notFound = false;

  get orderItemsTotal() {
    let total = 0;
    this.storeCustomerOrder.items.forEach(item => {
      total += (item.price * this.quantityPerItem(item))
    })
    return total;
  }

  async beforeMount() {
    this.loading = true

    const route = useRoute();

    if (route.query["payment_intent_client_secret"]) {
      this.clientSecret = route.query["payment_intent_client_secret"].toString();
    }

    const stripePromise = loadStripe(this.stripeKey as string);
    stripePromise.then(async (stripe) => {
      this.stripe = stripe;
      this.stripeLoaded = true;

      const { error, paymentIntent } = await stripe!.retrievePaymentIntent(this.clientSecret);

      if (error) {
        this.messages.push(error.message!);
        this.loading = false
      }
  
      this.messages.push(`Payment ${paymentIntent!.status}: ${paymentIntent!.id}`);

      if(paymentIntent!.status === "succeeded") {
          this.loading = false

          setTimeout(() => {
            this.loadInitialData();
          }, 1000);
      }
    });
  }

  // created() {

  // }

  mounted() {
    this.orderLoaded = false
    const {meta} = useMeta({})
  
    this.setMetaData(meta, {}, '', `Order Submitted | `)

    this.$watch('id', () => {
      setTimeout(() => {
        this.loadInitialData();
      }, 1000);
    })

    // this.$watch('order.id', () => {
    //   if (this.storeCustomerOrder.id) {
    //     this.setMetaData(meta, {}, '', `${this.storeCustomerOrder.referenceNumber ? ('Order #' + this.storeCustomerOrder.referenceNumber + ' Received')  : 'Order Received'} | `)
    //   }
    // })
  }

  formatStartDate(uppercase = true) {
    if(this.storeDetails.id) {
      return this.formatDate(this.storeDetails.startDate, uppercase);
    }
    return null;
  }

  formatEndDate(uppercase = true) {
    if(this.storeDetails.id) {
      return this.formatDate(this.storeDetails.endDate, uppercase);
    }
    return null;
  }

  itemQuantity(quantities: OrderCartItemQuantityViewModel[]) {
    return quantities.reduce((acc, quantity) => acc + quantity.quantity, 0);
  }

  removeStoreCartLocalStorage() {
    const vuex = localStorage.getItem("vuex");
    let store = JSON.parse(vuex!);
    let customStores: Array<{ storeId: string; customerId: string }> = [];
    let currentStore: { storeId: string; customerId: string } | null | undefined = null;

    if (store != null && store["customStores"] != null) {
      customStores = store["customStores"];
      currentStore = customStores.find((store: { storeId: string; customerId: string }) => store.customerId == this.id);
    }

    if (customStores.length && currentStore) {
      const remainingStores = customStores.filter((store) => store.storeId != this.id && store.customerId != this.id);
      store["customStores"] = remainingStores;
      localStorage.setItem("vuex", JSON.stringify({ ...store }));
    }
  }

  async getStoreDetails() {
    this.loading = true;
    this.notFound = false;
    if(this.storeCustomerOrder.id) {
      await Stores.storesGetActiveStoreIdGet(this.storeCustomerOrder.storeId)
      .then(async (res) => {
        if (res.data.succeeded) {
          this.storeDetails = res.data.resultData as StoreViewModel;
        }
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
        let errors = error.response.data.errors;
        if (errors) {
          errors.forEach((error: any) => {
            if(error.friendlyMessage !== 'Store not found') {
              this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
            } else {
              this.notFound = true;
            }
          });
        }
      });
    }
  }

  loadInitialData() {
    this.orderLoaded = false
    if (this.id) {
      Carts.cartsGetStoreCartGet(this.id)
        .then(async (res) => {
          if (res.data.succeeded) {
              this.storeCustomerOrder = res.data.resultData as StoreOrderCartViewModel;
              await this.getStoreDetails();
              this.removeStoreCartLocalStorage()
              this.orderLoaded = true;
              this.loading = false;

              // TODO: not tracking store orders, not getting info needed and prices are not clifton prices so more complex to navigate
              // if(!this.$route.query.loaded) {
              //   this.trackPurchase(res)
              //   this.facebookPurchaseEvent({value: this.order.orderTotal, currency: this.order.currency.code})

              //   let newQueryParams:any = {
              //     ...this.$route.query,
              //     loaded: true,
              //   };
              //   this.$router.replace({ params: { lang: this.$route.params.lang }, query: newQueryParams });
              // }
          }
        })
        .catch((error) => {
          console.log(error)
          this.orderLoaded = true
          this.loading = false;
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage });
          });
        });
    }
  }
}
