
import { mixins, Options, Vue } from "vue-class-component";
import StatementBanner from "@/components/banners/StatementBanner.vue";
import { useMeta } from 'vue-meta'
import { SetMetaData } from "@/mixins/utilities";

@Options({
  components: { StatementBanner },
})
export default class FAQsPage extends mixins(SetMetaData) {
  searchTerm = '';
  faqs = [
    {
      section: "DESIGN & SIZING",
      questions: [
        {
          question: "How do I add my own artwork to my clothing order?",
          answer: `<p>It's easy to add your own original artwork in JPEG, PNG or SVG format using our CLIFTON customization tool. If your logo has a white background our tool will automatically remove it for you. Prior to production, we may request a vector / SVG version of your artwork to ensure it comes out perfectly.</p>
          <p>If you have a print or pattern you want us to use, for best results we will need this in EPS / SVG / AI format. We can re-draw files for you if required for a flat rate fee. Alternatively, if you have a high resolution JPG / PNG image (1500+ pixels wide) we can use these but vector artwork is always required to ensure optimal print results (and accurate Pantone matching).</p>
          <p>As always, once you place your order and have cleared payment, we will send you a CLIFTON order pack PDF, this will show you exactly how your items will look once delivered along with logo dimensions and the sizes you have ordered. If at this point you need to make tweaks, our customer care team is on hand to help.</p>`,
        },
        {
          question: "I need help with my design!",
          answer: `<p>Don't worry, we have a dedicated design team on hand to help you get your design and order just right. Simply submit your inquiry and someone will be in touch to discuss your ideas typically within a few minutes.</p>
          <p>Our design team works with everyone on their order to ensure that all elements of the design are on-point. Some people know exactly what they want and the team has very little involvement, some have all of the elements but just aren't sure how to bring them together, and others are looking for a big dose of inspiration to bring their ideas to life. Whatever the case, our design team can't wait to work with you.</p>
          <p>Once you place your order, we include 2 full rounds of design adjustments. We recommend compiling all your adjustments (if you have any) into one email so that you can maximise this allowance. Additional design adjustments are then chargeable at GBP £30 / USD $40 per round.</p>`,
        },
        {
          question: "Do you offer custom options beyond what is listed on your site?",
          answer: `<p>We happily create fully bespoke orders and have yet to turn down an order, no matter how unusual. Contact us to learn more about how we can collaborate on the right bespoke clothing for your team.</p>`,
        },
        {
          question: "How do group orders work?",
          answer: `<p>A group clothing order lets you purchase the same piece for your whole group, but still allows the design flexibility for it to be unique to the individual. You might be doing a charity cycle, organising rugby tour swag, or organizing embroidered uniforms; whatever the order you can do so in bulk so your whole group looks the part. Each member can then choose their own size and even add their name and number, for a truly unique piece of custom clothing.</p>
          <p>We also recognize that people come in all shapes and sizes, and want to ensure that your entire group receives custom clothing they can wear and enjoy. That's why we request the sizing of everyone in your group, and make each piece of custom clothing to order on this basis. With most products, you can also add an individual's initials to their clothing, to add the final personal touch.</p>`,
        },
        {
          question: "Do you offer online stores for groups?",
          answer: `<p>We certainly do. CLIFTON offers a free service to help organizers arrange large orders with ease. Our custom online stores provide a unique website for your members, colleagues and friends to pick the items they wish to order, enter their required sizing and then most importantly make secure payment directly to us via credit or debit card. No more handling cash or chasing up bank transfers! <a class="link-to" href="/create-an-online-store" target="_blank">Learn more about this service here</a>.</p>`,
        },
        {
          question: "What sizes do you offer?",
          answer: `<p>Our clothing sizes cover Children's 2X-Small to X-Large, Men's 2X-Small to 6X-Large, and Women's Size 0 to 24. Most of our products are available in Classic (looser) or Slim (fitted) styles. Our tailored range is made to order in your exact measurements.</p>`,
        },
        {
          question: "How do I create my custom size?",
          answer: `<p><a class="link-to" href='/contact-us?from=/faqs' target="_blank">Contact us directly</a> and we can work with you on making your products in your exact size. Need help knowing what size you are? Each product has a sizing guide on the page.</p>`,
        },
        {
          question: "Can you do my measurements for me?",
          answer: `<p>We do not offer in-person tailoring appointments but we do provide measuring and sizing guides on each product's page, simply click the 'SIZING INFO' button on each product page.</p>`,
        },
        {
          question: "How do I properly care for my CLIFTON garments?",
          answer: `<p>We advise that all items are washed at 30 degrees celsius (cool wash) and allowed to dry naturally unless otherwise specified. Every product includes a care label so please be sure to check this before washing. You don't want to be the one in your group with a ‘smedium.'</p>
          <p>If you want your CLIFTON custom clothing to remain exactly how it fits you when you bought it, we recommend air drying instead of placing in the tumble dryer.</p>`,
        },
      ],
    },
    {
      section: "QUOTES & PAYMENTS",
      questions: [
        {
          question: "What methods of payment do you offer?",
          answer: `<p>We accept all major debit cards, credit cards, Apple Pay and iDEAL via our secure checkout. Alternatively, you can opt to pay via bank transfer in USD, GBP, CAD, EUR, HKD, AUD, NZD and more. We are unable to accept payment by PayPal, check/cheque, or money order (sorry!).</p>`,
        },
        {
          question: "Do you offer bulk discounts?",
          answer: `<p>Yes, we do. When you adjust the quantity of your order on our customization tool you will see the price discount update in real time. The price you see at checkout is the price you pay. If you are looking to order quantities of 250+ per product please contact us for our very best prices.</p>`,
        },
        {
          question: "Do you offer discounts to charity organizations?",
          answer: `<p>Every year we choose one organization to support. For example, we recently partnered with Duchenne UK and provided all the kits for their charity touch rugby match which raised GBP £25,000. Although we don't offer free customized sports kits to every charitable organization, we do work with multiple charities worldwide to provide the kit they need for their budget. In the past, we have provided kit for many charities from St John's Ambulance to the Endeavour Fund and Heads Together. If you're organising a big event and have a budget to work with, please get in touch with us and we can discuss your options.</p>`,
        },
        {
          question: "How accurate are your quotes?",
          answer: `<p>Prices are 100% accurate with no hidden extras or set up fees, just transparency from the get-go. The price you see when you order is what you will pay. We sometimes offer special prices, and the expiry date for that price will always be clearly shown. Many custom clothing companies charge setup fees per printed colour or embroidery, but rest assured, we won't.</p>`,
        },
        {
          question: "Who pays for import / customs fees?",
          answer: `<p>When orders are delivered internationally, they may be subject to import fees imposed by the destination country. We cover import duties if your order is being delivered to the USA, UK or The Netherlands. For other countries you will be responsible for paying the local import duties and taxes if levied.</p>`,
        },
      ],
    },
    {
      section: "PRODUCTION & DELIVERY",
      questions: [
        {
          question: "How long does it take until I receive my order?",
          answer: `<p>Get your custom clothing when you need it with - our current lead times are listed on each product page and also at checkout so you always have an accurate delivery window.</p>
          <p>All of our items are 100% bespoke and made to order so our expected delivery dates are an estimation, if you need a guaranteed delivery date please either select EXPRESS or contact us as we can usually help!</p>
          <p>Delivery timings start from your approval of the order pack (if opted in for) - this is emailed to you after payment and lists the items you have ordered, sizes & customizations for your pre-production adjustment or sign-off. Delays to payment or approvals before or during production will impact our ability to hit these timelines.</p>`,
        },
        {
          question: "What do I do if I have an issue with my order? For example, missing garments, wrong sizes etc.)",
          answer: `<p>Please contact <a class="link-to" href="mailto:studio@cliftonclothing.com">studio@cliftonclothing.com</a> with your concern (along with some images of the issue). If you have received your order and there are issues, reach out to us (<a class="link-to" href="mailto:studio@cliftonclothing.com">studio@cliftonclothing.com</a>) with your order number and some high resolution images and our friendly team will handle it from there.</p>        
          <p>We aim to resolve all order issues as quickly as possible. We are ISO9001 accredited for the quality of our management systems so you can go back to loving your bespoke customwear in no time.</p>`,
        },
        {
          question: "How do I track my order?",
          answer: `<p>You can view your orders, including the current target delivery date, at any time in your CLIFTON account order history. If you didn't create an account or checked out as a guest then you can <a class="link-to" href="/check-order-status" target="_blank">check any order status via this link</a>.</p>
          <p>Then when your order is collected by the courier we will send you an email with the tracking reference and link.</p>`,
        },
        {
          question: "Do you offer rush / express delivery?",
          answer: `<p>Need it fast? Please contact us directly to see if we can upgrade your order to a faster production and delivery speed. If we can do it, we'll confirm it and make it happen - guaranteed.</p>`,
        },
        {
          question: "Do you have a minimum order quantity or samples?",
          answer: `<p>The minimum order for most products is just 5pcs (with 1pc minimum on blazer and 50-100pc for accessories). Our customization tool will show the minimum order quanity for each product when opened.</p>`,
        },
        {
          question: "Can I pick up my order from your factory?",
          answer: `<p>Unfortunately, this is not possible. Our factory is a busy hive of activity and our insurers won't allow the risk of you being hit by a forklift in transit!</p>`,
        },
        {
          question: "Can you ship to multiple addresses?",
          answer: `<p>All items will be sent in one shipment to the order organizer for distribution. If you want delivery to multiple addresses (from multiple offices to home addresses worldwide) we can offer this for a flat rate per address.</p>`,
        },
        {
          question: "Can I order a free sample before purchasing?",
          answer: `<p>We do not offer free samples for  custom orders. Why? Because what you see on the screen is exactly what you will receive on delivery. If you do wish to see a sample, you will have to purchase that item with your exact measurements and design.</p>`,
        },
        {
          question: "Are your garments ethically and sustainably sourced and made?",
          answer: `<p>Yes they are. We only start making your items once your order is placed to avoid waste and holding onto excess stock. All fabrics, inks and dyes are tested to be AZO free, eco-friendly and free of hazardous substances. We are proud to only work with fabric suppliers who take these responsibilities, including the prohibition of any type of forced labour, as seriously as we do. We repurpose off-cuts to make sure nothing goes to waste. Once finished your items are packed into 100% biodegradable bags and shipped to your door with every ounce of carbon off-set at no charge to you.</p>
          <p>We manufacture all items within our own production facilities - our team is made up of very skilled craftspeople that do amazing work, we have very strict and extraordinarily high standards with regards to how our workers, your products and the environment are treated from initial designs to the day of delivery.</p>`,
        },
      ],
    },
    {
      section: "DATA DELETION",
      questions: [
        {
          question: "How can I delete my profile?",
          answer: `<p>An individual has the right to erasure, also known as ‘the right to be forgotten'. The principle underpinning this right is to enable an individual to request the deletion or removal of personal data where there is no compelling reason for its continued processing.</p>
          <p>Should you wish for CLIFTON to delete your data, please send an email to <a class="link-to" href="mailto:studio@cliftonclothing.com">studio@cliftonclothing.com</a> with the subject 'Please delete my CLIFTON account' and confirm the email address you have created a CLIFTON profile with, and we will delete your account and confirm this with you via email.</p>`,
        },
      ]
    }
  ];

  created() {
    const {meta} = useMeta({})
  
    this.setMetaData(meta, {}, '', 'Design & Resource Center | ')

    if(this.$route.query.search) {
      this.searchTerm = this.$route.query.search as string;
    }
  }

  mounted() {
    var collapseElementList = [].slice.call(document.querySelectorAll('#faqAccordian .collapse'))
    var collapseList = collapseElementList.map(function (collapseEl: HTMLElement) {
      // collapseEl.addEventListener('hidden.bs.collapse', function (e) {
      //   console.log(e.target);
      // });
      collapseEl.addEventListener('shown.bs.collapse', function (e) {
        const collapseObj = e.target as HTMLElement;
        const destination = collapseObj?.closest(".accordion-item") as HTMLElement;
        const navBar = document.querySelector("#navbar") as HTMLElement;
        const scrollOffset = destination.offsetTop - (navBar ? navBar.offsetHeight : 0) - 25;

        window.scrollTo({
          top: scrollOffset,
          behavior: 'smooth'
        });
      });
    })
  }

  saveSearchQuery() {
    this.$router.replace({
      name: 'FAQs',
      params: { lang: this.$route.params.lang },
      query: {
        search: this.searchTerm
      }
    })
  }

  filteredQuestions(questions: any) {
    return questions.filter((topic:any) => {
      return topic.question.toLowerCase().includes(this.searchTerm.toLowerCase()) || topic.answer.toLowerCase().includes(this.searchTerm.toLowerCase())
    })
  }
}
