
import { mixins, Options, Vue } from "vue-class-component";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import StatementBanner from "@/components/banners/StatementBanner.vue";
import FeaturedProducts from "@/components/featured/FeaturedProducts.vue";
import { BlogCategoryListViewModel, BlogListViewModel, BlogListViewModelPaginatedList } from "@/api-client";
import { BlogCategories, Blogs } from "@/network/api";
import HeadingBanner from "@/components/banners/HeadingBanner.vue";
import BlogPostTile from "@/components/blog/BlogPostTile.vue";
import { useMeta } from "vue-meta";
import { SetMetaData } from "@/mixins/utilities";
import Spinner from "@/components/spinner/Spinner.vue";

@Options({
  components: {
    Breadcrumbs,
    StatementBanner,
    FeaturedProducts,
    HeadingBanner,
    BlogPostTile,
    Spinner,
  },
})
export default class BlogPostsPage extends mixins(SetMetaData) {
  pageIndex = 1;
  blogPosts: BlogListViewModelPaginatedList = {
    hasNextPage: true,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 999999,
  };
  categories: Array<BlogCategoryListViewModel> = [];
  blogPostsItems: Array<BlogListViewModel> = [];
  selectedCategories: Array<string> = [];
  loading = false;

  created() {
    const { meta } = useMeta({});

    this.setMetaData(meta, {}, "", "Blog Posts | ");

    this.loadInitialData(1);
    this.loadCategories().then(() => {
      if(this.$route.query.category) {
        let categoriesQuery = this.$route.query.category;
        this.selectedCategories = []
        if(typeof categoriesQuery === "string") {
          this.selectedCategories.push(categoriesQuery);
        } else {
          categoriesQuery.forEach((category:any) => {
            this.selectedCategories.push(category);
          })
        }
      }
    })
  }

  mounted() {
    this.scroll();
  }

  isScrolledIntoView(el: any) {
    let rect = el.getBoundingClientRect() as DOMRect;
    let elemTop = rect.top;
    let elemBottom = rect.bottom;
    let isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
  }

  scroll() {
    window.onscroll = () => {
      let allRefs = this.$refs as any;
      let scrolledTo = allRefs.bottom;

      if (scrolledTo && this.isScrolledIntoView(scrolledTo)) {
        this.loadMoreBlogs();
      }
    };
  }

  loadMoreBlogs() {
    if (this.loading === false && this.blogPosts.hasNextPage === true) {
      this.loading = true;
      this.pageIndex += 1;

      this.loadInitialData(this.pageIndex);
    }
  }

  filterPosts(id: string) {
    if (this.selectedCategories.includes(id)) {
      this.selectedCategories = this.selectedCategories.filter((category) => {
        return category !== id;
      });
      this.$router.replace({
        path: this.$route.path,
        params: { lang: this.$route.params.lang },
        query: {
          category: this.selectedCategories,
        },
      });
    } else {
      this.selectedCategories.push(id);
      this.$router.replace({
        path: this.$route.path,
        params: { lang: this.$route.params.lang },
        query: {
          category: this.selectedCategories,
        },
      });
    }
    this.blogPostsItems = []
    this.loadInitialData(1);
  }

  loadInitialData(page = this.pageIndex) {
    this.loading = true;
    Blogs.blogsGet(page, 12, undefined, this.selectedCategories)
      .then((res) => {
        if (res.data.succeeded) {
          this.blogPosts = res.data.resultData as BlogListViewModelPaginatedList;
          this.blogPosts.items.forEach((item: any) => {
            //this is for lazy loading
            this.blogPostsItems.push(item);
          });
        }
        this.loading = false;
      })
      .catch((error) => {
console.log(error)
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }

  async loadCategories() {
    await BlogCategories.blogCategoriesGet()
      .then((res) => {
        if (res.data.succeeded) {
          this.categories = res.data.resultData as Array<BlogCategoryListViewModel>;
        }
      })
      .catch((error) => {
console.log(error)
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }

  // handleProductPageUpdate(page: number) {
  //   let refs = this.$refs as any;
  //   this.pageIndex = page;
  //   this.getPosts(this.pageIndex);
  //   window.scrollTo(0, refs.posts.offsetTop);
  // }
}
