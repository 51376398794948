
import { mixins, Options } from "vue-class-component";
import { Form, Field, ErrorMessage } from "vee-validate";
import { store } from "@/store";
import router from "@/router";
import { SocialProviders } from "@/store/modules/auth";
import Spinner from "@/components/spinner/Spinner.vue";
import { IsLoggedIn } from "@/mixins/utilities";
import { CallbackTypes } from "vue3-google-login";

@Options({
  components: { Form, Field, ErrorMessage, Spinner },
})
export default class LoginPage extends mixins(IsLoggedIn) {
  email?: string = "";
  password?: string = "";
  socialEnabled = true;
  showPassword = false;
  loading = false;
  errors: Array<string> | null = null;
  returnUrl = '/'

  created() {
    if((this.$route.query.clear == 'true') || this.isLoggedIn) {
      store.dispatch("auth/logout");
    }

    if(this.$route.query.email) {
      this.email = this.$route.query.email as string
    }
  }

  mounted() {
    this.returnUrl = router.currentRoute.value.query["returnUrl"] as string;
    if(this.returnUrl?.includes('login') || this.returnUrl?.includes('confirm-email') || this.returnUrl?.includes('-password') || this.returnUrl?.includes('404')) {
      this.returnUrl = '/'
    }
  }

  togglePassword() {
    let field = document.getElementById("login-password") as HTMLInputElement;
    this.showPassword = !this.showPassword;

    if (this.showPassword) {
      field.type = "text";
    } else {
      field.type = "password";
    }
  }

  fbResponse(data: any) {
    if(data?.authResponse) {
      this.loading = true;
      console.log("FB response", data);
      store
        .dispatch("auth/socialAuth", {
          provider: SocialProviders.Facebook,
          token: data.authResponse.accessToken,
        })
        .then(async (res) => {
          console.log("push route");
          this.loading = false;
          const lang = this.$route.params.lang as string;
          await router.push({path: this.returnUrl || "/"});
          if (!router.currentRoute.value.params.lang && lang) {
            await router.replace({ params: {lang: lang}});
          }
        })
        .catch((error) => {
          console.log(error)
          this.loading = false;
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
          });
        });
      this.loading = false;
    }
  }

  googleLogin: CallbackTypes.CredentialCallback = (response) => {
    if(response?.credential) {
      this.loading = true;
      console.log("Authorisation code", response);
      store
        .dispatch("auth/socialAuth", {
          provider: SocialProviders.Google,
          token: response.credential,
        })
        .then(async (res) => {
          console.log("push route");
          this.loading = false;
          const lang = this.$route.params.lang as string;
          await router.push({path: this.returnUrl || "/"});
          if (!router.currentRoute.value.params.lang && lang) {
            await router.replace({ params: {lang: lang}});
          }
        })
        .catch((error) => {
          console.log(error)
          this.loading = false;
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
          });
        });
        this.loading = false;
    }
  };

  appleSuccess(data: any) {
    if(data?.authorization?.code) {
      this.loading = true;
      var fName = "";
      var lName = "";

      if (data.userData != null) {
        fName = data.userData.name.firstName;
        lName = data.userData.name.lastName;
      }

      store
        .dispatch("auth/socialAuth", {
          provider: SocialProviders.Apple,
          token: data.authorization.code,
          firstname: fName,
          lastname: lName,
        })
        .then(async (res) => {
          this.loading = false;
          // const returnUrl = router.currentRoute.value.query["returnUrl"] as string;
          const lang = this.$route.params.lang as string;
          await router.push({path: this.returnUrl || "/"});
          if (!router.currentRoute.value.params.lang && lang) {
            await router.replace({ params: {lang: lang}});
          }
        })
        .catch((error) => {
          console.log(error)
          this.loading = false;
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
          });
        });
      this.loading = false;
    }
  }

  async login() {
    let isQuote = this.$route.query.returnUrl?.includes('?quote=')
    
    this.loading = true;
    this.errors = null;
    await store
      .dispatch("auth/login", {
        email: this.email,
        password: this.password,
        isQuote: isQuote,
      })
      .then(async (res) => {
        this.loading = false;
        const lang = this.$route.params.lang as string;
        await router.push({path: this.returnUrl || "/"});
        if (!router.currentRoute.value.params.lang && lang) {
          await router.replace({ params: {lang: lang}});
        }
      })
      .catch((error) => {
console.log(error)
        let errors = error.response.data.errors;

        errors.forEach((error: any) => {
          this.loading = false;
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });

            if (error.friendlyMessage === "You must confirm your email before logging in") {
              router.push({ name: "EmailVerification", params: { done: "no", lang: this.$route.params.lang } });
            }
          });
        });
      });
    this.loading = false;
  }
}
