
import { mixins, Options } from "vue-class-component";
import Spinner from "@/components/spinner/Spinner.vue";
import HeadingBanner from "@/components/banners/HeadingBanner.vue";
import { CalculatePrice, Currency, IsLoggedIn, ThousandSeparator, UploadPath } from "@/mixins/utilities";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import { Orders } from "@/network/api";
import { OrderItemAssetResupplyUpdateModel, OrderItemAssetResupplyViewModel } from "@/api-client";
import globalAxios from "axios";
import StatementBanner from "@/components/banners/StatementBanner.vue";

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

@Options({
  components: { Spinner, HeadingBanner, Breadcrumbs, StatementBanner },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
})
export default class ResupplyAssetsPage extends mixins(IsLoggedIn, UploadPath, ThousandSeparator, Currency, CalculatePrice) {
  id = "";
  currentStatus: number = STATUS_INITIAL;
  uploadedFiles: any;
  uploadError: any;
  resuppliedAssets: Map<string, string> = new Map();
  redrawRequestedAssets: Map<string, boolean> = new Map();
  approvedAssets: Map<string, boolean> = new Map();
  loading = false;
  assets: Array<OrderItemAssetResupplyViewModel> = [];
  showDarkerBg: Map<string, boolean> = new Map();
  resupplyComplete = false
  notFound = false
  selectedOptions : Map<string, string> = new Map();
  resupplyOptions = [ "Approve original asset", "Resupply this asset", "Request re-draw", ]
  debug = false
  displayPaymentButton = false

  get isInitial() {
    return this.currentStatus === STATUS_INITIAL;
  }
  get isSaving() {
    return this.currentStatus === STATUS_SAVING;
  }
  get isSuccess() {
    return this.currentStatus === STATUS_SUCCESS;
  }
  get isFailed() {
    return this.currentStatus === STATUS_FAILED;
  }

  created() {
    this.loadInitialData();
  }

  handleDarkerBg(id: string) {
    this.showDarkerBg.set(id, !this.showDarkerBg.get(id));
  }

  handleRequestRedraw(id: string) {
    if(this.redrawRequestedAssets.has(id)) {
      this.redrawRequestedAssets.delete(id);
    } else {
      this.redrawRequestedAssets.set(id, true);
    }
  }

  handleAssetName(id:any) {
    if(this.resuppliedAssets.has(id)) {
      let arr = this.resuppliedAssets.get(id)!.split('/');
      return arr[arr.length - 1];
    }
    
  }

  setSelectedOption(id:string, index:any, {target}:any) {
    let option : string = target.value ;
    
    this.deleteUploadedItem(id, index)
    this.redrawRequestedAssets.delete(id);
    this.approvedAssets.delete(id);

    if(option === 'Request re-draw') {
      this.redrawRequestedAssets.set(id, true);
    }

    if(option === 'Approve original asset') {
      this.approvedAssets.set(id, true);
    }
    this.selectedOptions.set(id, option);
  }

  filesChange(fileList: Array<any>, id: string) {
    const formData = new FormData();
    if (!fileList.length) return;
    formData.append("file", fileList[0]);
    this.save(formData, id);
  }

  save(formData: any, id:string) {
    this.currentStatus = STATUS_SAVING;

    this.upload(formData)
      .then((x: any) => {
        this.resuppliedAssets.set(id, x);
        this.currentStatus = STATUS_INITIAL;
      })
      .catch((err: any) => {
        this.uploadError = err.response;
        this.currentStatus = STATUS_FAILED;
      });
  }

  deleteUploadedItem(id: string, index: any) {
    if(this.resuppliedAssets.has(id)) {
      let refs = this.$refs as any;
      refs.file.forEach((file: any) => {
        file.value = ""
      });
      this.resuppliedAssets.delete(id);
    }
  }

  async upload(formData: any) {
    const url = `${this.uploadPath}`;
    return globalAxios
      .post(url, formData)
      .then((x: any) => x.data)
      .then((img: any) => img.relativePath);
  }

  submitResuppliedAssets() {
    this.loading = true;
    let reDrawCount = 0
    let updatedAssets = [] as Array<OrderItemAssetResupplyUpdateModel>
    this.assets.forEach((asset: any) => {
      let hasImage = this.resuppliedAssets.has(asset.id);
      let hasRequest = this.redrawRequestedAssets.has(asset.id);
      let hasApprovedOriginal = this.approvedAssets.has(asset.id);

      if(hasRequest) {
        reDrawCount += 1
      }
      let updatedAsset = {
        id: asset.id,
        fileUrl: hasImage ? this.resuppliedAssets.get(asset.id) : asset.fileUrl,
        requestRedraw: hasRequest,
        acceptCurrentAsset: hasApprovedOriginal,
      } as OrderItemAssetResupplyUpdateModel

      updatedAssets.push(updatedAsset);
    })
      Orders.ordersResupplyOrderItemAssetsOrderIdPost(this.id, updatedAssets)
        .then((res) => {
          if (res.data.succeeded) {
            this.resupplyComplete = true
            
            if(reDrawCount > 0) {
              this.displayPaymentButton = true
            }
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error)
          this.loading = false;
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage });
          });
          console.log(error);
        });
  }

  loadInitialData() {
    if (this.id) {
      this.loading = true;
      Orders.ordersGetOrderItemAssetsForResuplyOrderIdGet(this.id)
        .then((res) => {
          if (res.data.succeeded) {
            this.assets = res.data.resultData as Array<OrderItemAssetResupplyViewModel>;
            this.showDarkerBg.clear();
            this.assets.forEach((asset) => {
              this.showDarkerBg.set(asset.id, false);
              this.selectedOptions.set(asset.id, '');
            });
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);

          if(error.response.status == 404) {
            this.notFound = true
          } else if(error.response.status == 401) {
            this.$notify({ type: "error", text: 'Please log in to the relevant account to view this page.', ignoreDuplicates: true, duration: -1 });
            this.$router.push({ name: 'Login', params: {lang: this.$route.params.lang}, query: { returnUrl: this.$route.fullPath }});
          } else {
            let errors = error.response.data.errors;

            if(error.response.data?.resultFailureType === 'Authorization') {
              this.$notify({ type: "error", text: 'Please log in to the relevant account to view this page.', ignoreDuplicates: true, duration: -1 });
              this.$router.push({ name: 'Login', params: {lang: this.$route.params.lang}, query: { returnUrl: this.$route.fullPath }});
            } else {
              errors.forEach((error: any) => {
                this.$notify({ type: "error", text: error.friendlyMessage });
              });
            }
          }
        });
    }
  }
}
