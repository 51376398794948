
import { store } from "@/store";
import { mixins, Options, Vue } from "vue-class-component";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import Spinner from "@/components/spinner/Spinner.vue";
import HeadingBanner from "@/components/banners/HeadingBanner.vue";
import ProfileDetails from "@/components/profile/ProfileDetails.vue";
import AddressBook from "@/components/profile/address-book/AddressBook.vue";
import OrderHistory from "@/components/profile/order-history/OrderHistory.vue";
import CustomStores from "@/components/profile/custom-stores/CustomStores.vue";
import RecentlyViewedProducts from "@/components/profile/RecentlyViewedProducts.vue";
import SavedDesigns from "@/components/profile/SavedDesigns.vue";
import ResetPassword from "@/components/profile/ResetPassword.vue";
import { UserAddresses } from "@/network/api";
import { UserAddressListViewModel } from "@/api-client";
import router from "@/router";
import { useMeta } from 'vue-meta'
import { SetMetaData, ScrollTop } from "@/mixins/utilities";
import utilities from "@/composables/utilities";

@Options({
  components: { Breadcrumbs, Spinner, HeadingBanner, ProfileDetails, AddressBook, OrderHistory, CustomStores, RecentlyViewedProducts, SavedDesigns, ResetPassword },
  beforeRouteUpdate(to, from) {
    this.selectedSection = to.query.section;
  },
})
export default class ProfilePage extends mixins(SetMetaData, ScrollTop) {
  profileSections = [
    {
      name: "Profile Details",
      slug: "profile-details",
    },
    {
      name: "Address Book",
      slug: "address-book",
    },
    {
      name: "Order History",
      slug: "order-history",
    },
    {
      name: "Online Stores",
      slug: "online-stores",
    },
    {
      name: "Recently Viewed",
      slug: "recently-viewed-products",
    },
    {
      name: "Saved Designs",
      slug: "saved-designs",
    },
    {
      name: "Reset Password",
      slug: "reset-password",
    },
  ];
  selectedSection: any = "profile-details";
  addresses: Array<UserAddressListViewModel> = [];
  loading = false;

  topAnchor() {
    this.scrollTop(".profile-content-container", false);
  }

  get formatRoute() {
    return this.$router.resolve({ name: 'Profile', params: {lang: this.$route.params.lang}, query: { section: 'profile-details' } }).href;
  }

  get sectionName() {
    return this.profileSections.find((section) => section.slug === this.selectedSection)?.name || '';
  }

  formatSectionName(name: string) {
    return name.split("-").join(" ");
  }

  created() {
    if (this.$route.query.section) {
      this.selectedSection = this.$route.query.section;
    } else {
      this.selectedSection = 'profile-details'
      this.$router.replace({ name: 'Profile', params: { lang: this.$route.params.lang }, query: { section: 'profile-details' } });
    }

    const {meta} = useMeta({})
  
    this.setMetaData(meta, {}, '', `${this.sectionName} | `)

    this.$watch('sectionName', () => {
      this.setMetaData(meta, {}, '', `${this.sectionName} | `)
    })

    this.loadAllAddresses();

    console.log("route", this.$route.query.section);
    console.log("selected", this.selectedSection);
  }

  get userProfile() {
    return store.getters["user/profile"];
  }

  loadAllAddresses() {
    this.loading = true;
    UserAddresses.userAddressesGet(1, 999999 )
    .then((res) => {
      if(res.data.succeeded) {
        this.addresses = res.data.resultData!.items ;
        //debugger
      } 
      this.loading = false;     
    })
    .catch((error) => {
console.log(error)
      this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
    });
  }

  async logout() {
    await store.dispatch("auth/logout");
    this.$notify({ type: "success", text:  `Successfully logged out.` });
    const authedPages = ['Profile', 'Cart', 'Checkout', 'PaymentComplete', 'OutstandingPaymentComplete', 'EFTPaymentPage', 'OrderPayments', 'ResupplyAssets', 'Quote']
    if(authedPages.includes(this.$route.name as string)) {
      this.$router.push({ name: 'Home', params: {lang: this.$route.params.lang} });
    }
    //router.push({ name: "Login", params: {lang: this.$route.params.lang}, query: { returnUrl: this.$route.fullPath } });
  }
}
