
import { mixins, Options } from "vue-class-component";
import Spinner from "@/components/spinner/Spinner.vue";
import HeadingBanner from "@/components/banners/HeadingBanner.vue";
import { CalculatePrice, IsLoggedIn, ThousandSeparator } from "@/mixins/utilities";
import { Carts, Orders, Payments } from "@/network/api";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import { EftBankDetails, OrderOutstandingPaymentViewModel, PaymentProvider } from "@/api-client";
import StripeCheckoutForm from "@/components/checkout-process/checkout/StripeCheckoutForm.vue";
import { marked } from "marked";
import { useMeta } from "vue-meta";
import { SetMetaData } from "@/mixins/utilities";

@Options({
  components: { Spinner, HeadingBanner, Breadcrumbs, StripeCheckoutForm },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
})
export default class OrderPaymentsPage extends mixins(IsLoggedIn, CalculatePrice, ThousandSeparator, SetMetaData) {
  orderId: any = "";
  stripeKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
  stripe: any;
  stripeLoaded: any;
  order: OrderOutstandingPaymentViewModel = {
    id: "",
    referenceNumber: "",
    outstandingPaymentValue: 0,
    currency: {
      id: "",
      name: "",
      code: "",
      symbol: "",
      pricingMultiplier: 0,
      decimalPlaces: 0,
    },
    outstandingPaymentConvertedValue: 0,
  };
  loading = false;
  isCardPayment = "";
  bankDetails: EftBankDetails = {
    bankTransferDetails: "",
    reference: "",
  };
  termsEFT = false;
  paymentProviders: Array<PaymentProvider> = []
  messages: Array<string> = [];

  clientSecret = "";

  errors = null;

  created() {
    this.errors = null;
    const { meta } = useMeta({});

    this.setMetaData(meta, {}, "", "Outstanding Payment | ");

    if (this.$route.params.id) {
      this.orderId = this.$route.params.id;
    }
    this.loadInitialData();

    this.$watch("isCardPayment", () => {
      if (this.isCardPayment === "eft") {
        if (!this.bankDetails.bankTransferDetails) {
          this.loading = true;
          Payments.paymentsGetEftBankDetailsIdGet(this.orderId)
            .then((res) => {
              if (res.data.succeeded) {
                this.bankDetails = res.data.resultData as EftBankDetails;
              }
              this.loading = false;
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;
              let errors = error.response.data.errors;
              errors.forEach((error: any) => {
                this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
              });
            });
        }
      }
    });
  }

  handlePaymentType({ target }: any) {
    this.isCardPayment = target.value;
  }

  get formattedBankDetails() {
    if (this.bankDetails.bankTransferDetails) {
      let markedDetails = marked(this.bankDetails.bankTransferDetails);
      markedDetails = markedDetails.replace("<p>", "");
      markedDetails = markedDetails.replace("</p>", "");
      markedDetails = markedDetails.replaceAll("<strong>", '<div class="d-flex mb-1 align-items-baseline flex-wrap"><h6 class="bank-dets-headings m-0 me-2">');
      markedDetails = markedDetails.replaceAll("</strong>", "</h6>");
      markedDetails = markedDetails.replaceAll("<em>", '<p class="bank-dets-info m-0 mb-1">');
      markedDetails = markedDetails.replaceAll("</em>", "</p></div>");

      return markedDetails;
    }
    return "";
  }

  EFTCheckout() {
    this.loading = true;
    Payments.paymentsCompleteCheckoutEftIdPut(this.orderId)
      .then((res) => {
        if (res.data.succeeded) {
          this.$router.push({ name: "OutstandingPaymentComplete", params: { id: this.orderId, lang: this.$route.params.lang }, query: { eft: "true" } });
        }
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }

  async checkPaymentProviders() {
    this.loading = true
    await Carts.cartsGetPaymentProvidersForOrderIdGet(this.orderId)
    .then((res) => {
      if(res.data.succeeded) {
        this.paymentProviders = res.data.resultData as Array<PaymentProvider>       
      }
    })
    .catch((error) => {
      console.log(error)
      this.loading = false;
      let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }

  async loadInitialData() {
    if (this.orderId) {
      this.loading = true;
      await Orders.ordersGetOrderOutstandingPaymentOrderIdGet(this.orderId)
        .then((res) => {
          if (res.data.succeeded) {
            this.order = res.data.resultData as OrderOutstandingPaymentViewModel;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;

          if(error.response.status == 401) {
            this.$notify({ type: "error", text: 'Please log in to the relevant account to view this page.', ignoreDuplicates: true, duration: -1 });
            this.$router.push({ name: 'Login', params: {lang: this.$route.params.lang}, query: { returnUrl: this.$route.fullPath }});
          } else {
            this.errors = error.response.data.errors;
            let errors = error.response.data.errors;
            errors.forEach((error: any) => {
              if(error.friendlyMessage == 'You do not have access to this order') {
                this.$notify({ type: "error", text: 'Please log in to the relevant account to view this page.', ignoreDuplicates: true, duration: -1 });
                this.$router.push({ name: 'Login', params: {lang: this.$route.params.lang}, query: { returnUrl: this.$route.fullPath }});
              }
              this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
            });
          }
        });

        await this.checkPaymentProviders()
        this.loading = false
    }
  }
}
