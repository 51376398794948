
import { CalculatePrice, Cart, Currency, FormatDate, ThousandSeparator } from "@/mixins/utilities";
import { mixins, Options } from "vue-class-component";
import SecurePaymentInfo from "@/components/checkout-process/SecurePaymentInfo.vue";
import { CurrencyViewModel, DeliveryType, OrderCartViewModelVoucher, OrderCartDeliveryOptionViewModel, PaymentProvider, VoucherType, VoucherApplyToType } from "@/api-client";
import { Form, Field, ErrorMessage } from "vee-validate";
import countries from "@/assets/country-codes/countries.json";
import { store } from "@/store";
import { Carts } from "@/network/api";

@Options({
  components: { SecurePaymentInfo, Form, Field, ErrorMessage },
  props: {
    cart: { default: null },
    currency: { default: {
        id: "",
        name: "",
        code: "USD",
        symbol: "",
        pricingMultiplier: 0,
        decimalPlaces: 2,
      }
    },
    totalPrice: { default: 0 },
    deliveryInfo: { default: null },
    deliveryOptions: { default: null },
    isCustomerQuote: { default: false },
    accessKey: { default: undefined },
    showSummary: { default: true },
  },
  emits: ["upgradeDelivery", "voucherUpdate", "checkout", "adminQuote", "customerQuote", "openSummary"],
})
export default class CartSummary extends mixins(CalculatePrice, ThousandSeparator, FormatDate) {
  countryCodes = countries;
  cart: any = null;
  currency: CurrencyViewModel = {
      id: "",
      name: "",
      code: "USD",
      symbol: "",
      pricingMultiplier: 0,
      decimalPlaces: 2,
    }
  totalPrice = 0;
  deliveryInfo: OrderCartDeliveryOptionViewModel = {
    deliveryTypeId: DeliveryType.Standard,
    deliveryTypeName: "",
    deliveryDays: 0,
    productionDays: 0,
    deliveryCost: 0.0,
    deliveryDelayDays: 0,
    productionDelayDays: 0
  };
  deliveryOptions: Array<OrderCartDeliveryOptionViewModel> = [];
  selectedDialCode = "+1";
  phoneNumber = "";
  customerMessage = "";
  messageRequired = false;
  quoteAction = "save";
  showQuoteMessage = false;
  showPaymentMethods = false;
  allowCard = false;
  allowEFT = false;
  voucherCode = "";
  accessKey = undefined;
  voucher: OrderCartViewModelVoucher | null = null;
  voucherError: string | null = null;
  voucherSuccess: true | null = null;
  showGiftsVoucher = false;
  voucherApplyTypes: any = {
    [VoucherApplyToType.TotalCost.toString()]: 'this promo code will apply to the total cart value',
    [VoucherApplyToType.ProductCost.toString()]: 'this promo code will apply to a product within the cart',
    [VoucherApplyToType.DeliveryCost.toString()]: 'this promo code will apply to the order delivery cost'
  }

  // {
  //   id: '',
  //   voucherTypeId: VoucherType.Absolute,
  //   value: 0,
  //   redemptionCode: '',
  //   minimumCartValue: null,
  //   maximumRedemptions: null,
  //   startDate: null,
  //   endDate: null
  // }

  get totalOrderPrice() {
    if (this.deliveryInfo) {
      const subtotal = this.calculatePrice(this.totalPrice + this.deliveryInfo.deliveryCost, this.currency.pricingMultiplier);
      const total = Number(subtotal) + this.cart.additionalCostsConvertedTotal - Number(this.voucherFinalValue) - Number(this.cart.paymentsConvertedTotal);
      if(total > 0) {
        return total.toFixed(2);
      }
    }
    return 0;
  }

  get subTotalOrderPrice() {
    const subtotal = this.calculatePrice(this.totalPrice, this.currency.pricingMultiplier);
    const total = Number(subtotal) + this.cart.additionalCostsConvertedTotal;
    return total.toFixed(2);
  }

  get voucherFinalValue() {
    if (this.voucher && this.cartVoucherCriteria.includes("voucherValid")) {
      if (this.voucher.productVoucherAmount) {
        return Number(this.calculatePrice(this.voucher.productVoucherAmount, 1)).toFixed(2);
      }
      else if (this.voucher.deliveryVoucherAmount) {
        return Number(this.calculatePrice(this.voucher.deliveryVoucherAmount, 1)).toFixed(2);
      }
      else if (this.voucher.totalVoucherAmount) {
        return Number(this.calculatePrice(this.voucher.totalVoucherAmount, 1)).toFixed(2);
      }
      return 0;
    }
    return 0;
  }

  // get voucherConvertedPrice() {
  //   return this.calculatePrice(this.voucherOriginalPrice, this.currency.pricingMultiplier)
  // }

  get cartVoucherCriteria() {
    if (this.voucher) {
      let criteria: Array<any> = [];
      if (this.voucher.minimumCartValue && this.subTotalOrderPrice) {
        const valueReached = +this.subTotalOrderPrice - +this.calculatePrice(this.voucher.minimumCartValue, this.currency.pricingMultiplier) >= 0;

        if (valueReached) {
          criteria.push("cartValueValid");
        } else {
          criteria.push("cartValueNotValid");
        }
      }

      if (this.voucher.startDate && this.voucher.endDate) {
        const now = new Date();
        const start = new Date(this.voucher.startDate);
        const end = new Date(this.voucher.endDate);

        if (now > start && now < end) {
          criteria.push("cartDateValid");
        } else {
          criteria.push("cartDateNotValid");
        }
      }

      if (!criteria.includes("cartValueNotValid") && !criteria.includes("cartDateNotValid")) {
        criteria.push("voucherValid");
      }

      return criteria;
    }
    return ["noVoucherApplied"];
  }

  get cartVoucherSuggestions() {
    if (this.voucher) {
      let datePending = null;
      let dateExpired = null;
      // const minimumConverted = this.voucher?.minimumCartValue ? this.calculatePrice(this.voucher?.minimumCartValue, this.currency.pricingMultiplier) : null;

      const voucherConvertedValue = this.voucher.voucherTypeId === "Absolute" ? `${this.currency.symbol}${this.calculatePrice(this.voucher.value, this.currency.pricingMultiplier)}` : `${this.voucher.value * 100}%`;

      const outstandingValueConverted = this.voucher.minimumCartValue ? `${this.currency.symbol}${(+this.calculatePrice(this.voucher.minimumCartValue, this.currency.pricingMultiplier) - +this.subTotalOrderPrice).toFixed(2)} ` : null;

      if (this.cartVoucherCriteria.includes("cartDateNotValid") && this.voucher.startDate && this.voucher.endDate) {
        const now = new Date();
        const start = new Date(this.voucher.startDate);
        const end = new Date(this.voucher.endDate);

        datePending = now < start;
        dateExpired = now > end;
      }

      return {
        cartValueNotValid: this.cartVoucherCriteria.includes("cartValueNotValid") ? `Add ${outstandingValueConverted} for ${voucherConvertedValue} off!` : null,

        cartDateNotValid: this.cartVoucherCriteria.includes("cartDateNotValid") && dateExpired ? `Promo Code expired ${this.formatDate(this.voucher.endDate)}` : this.cartVoucherCriteria.includes("cartDateNotValid") && datePending ? `Promo code valid</br>${this.formatDate(this.voucher.startDate)} - ${this.formatDate(this.voucher.endDate)}` : null,

        cartDateValid: this.cartVoucherCriteria.includes("cartDateValid") ? `Promo Code valid</br>${this.formatDate(this.voucher.startDate)} - ${this.formatDate(this.voucher.endDate)}` : null,
      };
    }
    return {
      cartValueNotValid: null,
      cartDateNotValid: null,
      cartDateValid: null,
    };
  }

  get itemsInCart() {
    if (this.cart) {
      let totalItems = 0;
      this.cart.items.forEach((item: any) => {
        item.quantities.forEach((itemQuanities: any) => {
          totalItems += itemQuanities.quantity;
        });
      });
      if (totalItems <= 9999) {
        return totalItems;
      }
      return "9999+";
    }
    return 0;
  }

  get isAdminQuote() {
    if (this.cart.isQuote) {
      return true;
    }
    return false;
  }

  get sortedDialCodes() {
    return this.countryCodes.sort((a: any, b: any) => {
      return a.dialCode - b.dialCode;
    });
  }

  get expressAvailable() {
    if (this.deliveryInfo) {
      let hasExpress = this.deliveryOptions.find((x) => x.deliveryTypeId === DeliveryType.Express) || null;
      let hasStandard = this.deliveryOptions.find((x) => x.deliveryTypeId === DeliveryType.Standard) || null;

      if (!hasExpress || this.deliveryInfo.deliveryTypeId === "Express") {
        return false;
      } else {
        if (hasStandard && hasStandard.deliveryCost < hasExpress.deliveryCost) {
          return hasExpress.deliveryCost - hasStandard.deliveryCost;
        }
        return hasExpress.deliveryCost;
      }
    }
    return false;
  }

  get expressDeliveryDate() {
    let deliveryType = {} as any;
    this.deliveryOptions.forEach((item: any) => {
      if (item.deliveryTypeId === "Express") {
        deliveryType = item;
      }
    });

    let date = new Date();
    let deliveryDays = deliveryType.deliveryDays;
    let totalDays = deliveryDays + deliveryType.productionDays + deliveryType.deliveryDelayDays + deliveryType.productionDelayDays;

    if (totalDays > 0) {
      date.setDate(date.getDate() + totalDays);
      return this.formatDate(date);
    }
    return "";
  }

  created() {
    this.voucherError = null;
    this.voucherSuccess = null;
    this.voucherCode = "";
    this.handleCart();
    this.resetGiftsVoucher();

    this.$watch("cart.id", () => {
      this.handleCart();
    });
    this.$watch("cart.items", () => {
      this.handleCart();
    });
    this.$watch("cart.voucher", () => {
      this.handleCart();
    });
    this.$watch("cart.deliveryTypeId", () => {
      this.handleCart();
    });

    window.onresize = () => this.resetGiftsVoucher();
  }

  resetGiftsVoucher() {
    if ((window.innerWidth > 992 && !this.showGiftsVoucher) || (window.innerWidth < 992 && (this.voucherCode || this.cart.isGift))) {
      this.showGiftsVoucher = true;
    } else if (window.innerWidth < 992) {
      this.showGiftsVoucher = false;
    }
  }

  handleCart() {
    if (this.cart) {
      if (this.isAdminQuote) {
        this.handlePhoneNumber();
      }

      if (this.cart.allowedPaymentProviders.includes("Stripe")) {
        this.allowCard = true;
      }

      if (this.cart.allowedPaymentProviders.includes("EFT")) {
        this.allowEFT = true;
      }

      if (this.cart.voucher?.id) {
        this.voucher = { ...this.cart.voucher };
        // this.voucherCode = this.voucher?.redemptionCode as string;
      }
      else {
        this.voucher = null;
      }
    }
  }

  applyVoucher() {
    this.voucherError = null;
    this.voucherSuccess = null;

    Carts.cartsApplyVoucherToCartPut(this.cart.id, this.voucherCode, this.accessKey || undefined)
      .then((res) => {
        if (res.data.succeeded) {
          this.voucher = res.data.resultData as OrderCartViewModelVoucher;
          this.voucherCode = "";
          this.voucherSuccess = true;
          this.$notify({ type: "success", duration: -1, text: `Promo Code added*<br/><br/>*<span style="font-size:small;">Certain criteria may need to be met for discount to apply.</span>` });
          this.$emit('voucherUpdate', this.voucher)
          // if (this.isVoucherUpdateRefresh(this.voucher)) {
          // }
          // this.handleVoucher()
        }
      })
      .catch((error) => {
        console.log(error);
        let errors = error.response.data.errors;
        this.voucherError = errors[0].friendlyMessage;
      });
  }

  isVoucherUpdateRefresh(voucherUpdate: OrderCartViewModelVoucher) {
    if (voucherUpdate.voucherApplyToTypeId == VoucherApplyToType.ProductCost && voucherUpdate.voucherTypeId == VoucherType.Percentage) {
      return true
    }
    return false
  }

  handlePaymentProviders({ target }: any) {
    if (this.cart.allowedPaymentProviders.includes(target.id as PaymentProvider)) {
      this.cart.allowedPaymentProviders = this.cart.allowedPaymentProviders.filter((item: PaymentProvider) => item !== target.id);
    } else {
      this.cart.allowedPaymentProviders.push(target.id);
    }
  }

  handlePhoneNumber() {
    if (this.cart.customerContact.phoneNumber && this.cart.customerContact.phoneNumber !== "") {
      let array = this.cart.customerContact.phoneNumber.split("-");
      if (array.length >= 2) {
        this.selectedDialCode = array[0];
        array.shift();
        this.phoneNumber = array.join("-");
      }
    }
    if (this.selectedDialCode == "") {
      let selectedCountry = store.getters["location/country"];

      if (selectedCountry) {
        let matchedCountry = this.countryCodes.find((country: any) => {
          return selectedCountry.code === country.isoCode;
        });

        if (matchedCountry) {
          this.selectedDialCode = matchedCountry.dialCode;
        }
      }

      if (!this.selectedDialCode) {
        this.selectedDialCode = "+1";
      }
    }
  }

  handleAdminQuote() {
    this.cart.customerContact.phoneNumber = `${this.selectedDialCode}-${this.phoneNumber}`;
    this.$emit("adminQuote", this.quoteAction);
  }

  handleRequestChanges() {
    if (this.customerMessage) {
      this.messageRequired = false;
      this.$emit("customerQuote", { message: this.customerMessage, accepted: false });
    } else {
      this.$emit('openSummary')
      setTimeout(() => {
        let refs = this.$refs as any;
        refs.note.focus();
        this.$notify({ type: "error", text: "Notes are required when requesting changes." });
        this.messageRequired = true;
      }, 0);
    }
  }

  handleToEquire() {
    let leadIn = window.location.pathname;
    const routeData = this.$router.resolve({ name: "Enquiries", params: { lang: this.$route.params.lang }, query: { cart: this.cart.id, from: leadIn, store: this.cart.storeId || undefined } });
    window.open(routeData.href, '_blank');
  }
}
