
import { mixins, Options } from "vue-class-component";
import Spinner from "@/components/spinner/Spinner.vue";
import HeadingBanner from "@/components/banners/HeadingBanner.vue";
import OrderSummary from "@/components/checkout-process/checkout/OrderSummary.vue";
import { IsLoggedIn, QuantityPerItem } from "@/mixins/utilities";
import { loadStripe } from "@stripe/stripe-js";
import { useRoute } from "vue-router";
import { store } from "@/store";
import { Orders } from "@/network/api";
import { OrderHistoryViewModel, OrderPublicStatus } from "@/api-client";
import CompleteOrderSummary from "@/components/checkout-process/payment-complete/CompleteOrderSummary.vue";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import { useMeta } from 'vue-meta'
import { SetMetaData } from "@/mixins/utilities";
import { TrackPurchase } from "@/mixins/gtag";
import { FacebookPurchaseEvent } from "@/seo/facebook-pixel-helper";

@Options({
  components: { Spinner, HeadingBanner, OrderSummary, CompleteOrderSummary, Breadcrumbs, SetMetaData },
  props: {
    id: {
      type: String,
      required: true,
    }
  },
})
export default class PaymentCompletePage extends mixins(IsLoggedIn, QuantityPerItem, SetMetaData, TrackPurchase, FacebookPurchaseEvent) {
  id: any = "";
  stripeKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
  stripe: any;
  stripeLoaded: any;
  order: OrderHistoryViewModel = {
    id: "",
    referenceNumber: "",
    orderPublicStatusId: OrderPublicStatus.Processing,
    marketingOptIn: false,
    orderPlacementDate: "",
    expectedDeliveryDate: "",
    showOrderPackApprovalLink: false,
    showAssetResupplyLink: false,
    showOutstandingPaymentLink: false,
    additionalCostsConvertedTotal: 0,
    currency: {
      id: "",
      name: "",
      code: "",
      symbol: "",
      pricingMultiplier: 1,
      decimalPlaces: 2,
    },
    orderTotal: 0,
    orderTotalIncludingVoucher: 0,
    orderItems: [],
    deliveryTotal: 0,
    adhocTotal: 0,
    orderInvoices: [],
    customerContacts: [],
    paymentsConvertedTotal: 0,
    paymentsTotal: 0
  };
  loading = false;
  orderLoaded = false
  messages: Array<string> = [];
  tryCount = 0
  accessKey: string | undefined = undefined
  clientSecret = "";

  async beforeMount() {
    if(!this.$route.query.balance) {
      this.loading = true

      const route = useRoute();

      if (route.query["payment_intent_client_secret"]) {
        this.clientSecret = route.query["payment_intent_client_secret"].toString();
        const stripePromise = loadStripe(this.stripeKey as string);

        stripePromise.then(async (stripe) => {
          this.stripe = stripe;
          this.stripeLoaded = true;

          const { error, paymentIntent } = await stripe!.retrievePaymentIntent(this.clientSecret);

          if (error) {
            this.messages.push(error.message!);
            this.loading = false
          }
      
          this.messages.push(`Payment ${paymentIntent!.status}: ${paymentIntent!.id}`);
          if(paymentIntent!.status === "succeeded") {
            store.dispatch("checkout/refreshCart", {
              id: null,
              accessKey: this.$route.params.accessKey || undefined
            }).then(() => {
              this.loading = false              

              setTimeout(() => {
                this.loadInitialData();
              }, 1000);
              
            })
          }
        });
      } else {
        this.loading = false;
        this.orderLoaded = true;
      }
    }
  }

  created() {
    if (this.$route.params.accessKey) {
      this.accessKey = this.$route.params.accessKey as string;
    }

    if(this.$route.query.balance == 'none') {
      setTimeout(() => {
        this.loadInitialData();
      }, 1000);
    }
  }

  mounted() {
    this.orderLoaded = false
    const {meta} = useMeta({})
  
    this.setMetaData(meta, {}, '', `Order Received | `)

    

    this.$watch('id', () => {
      setTimeout(() => {
        this.loadInitialData();
      }, 1000);
    })

    this.$watch('order.id', () => {
      if (this.order.id) {
        this.setMetaData(meta, {}, '', `${this.order.referenceNumber ? ('Order #' + this.order.referenceNumber + ' Received')  : 'Order Received'} | `)
      }
    })

    // debugger
    // store.dispatch("checkout/refreshCart", {
    //   id: null,
    // });
  }

  // printSummary() {
  //   let allRefs = this.$refs as any

  //     var data = allRefs.summary.innerHTML;
  //     debugger
  //     var myWindow = window.open('', 'my div', 'height=400,width=600') as any;
  //     myWindow.document.write('<html><head><title>my div</title>');
  //     /*optional stylesheet*/ //myWindow.document.write('<link rel="stylesheet" href="main.css" type="text/css" />');
  //     myWindow.document.write('</head><body >');
  //     myWindow.document.write(data);
  //     myWindow.document.write('</body></html>');
  //     myWindow.document.close(); // necessary for IE >= 10

  //     myWindow.onload=function(){ // necessary if the div contain images
  //         myWindow.focus(); // necessary for IE >= 10
  //         myWindow.print();
  //         myWindow.close();
  //     };
  // }

  loadInitialData() {
    this.orderLoaded = false
    if (this.id) {
      this.loading = true;
      Orders.ordersGetOrderOrderIdGet(this.id, this.accessKey || undefined)
        .then((res) => {
          if (res.data.succeeded) {
            if(res.data.resultData?.orderPlacementDate && res.data.resultData?.expectedDeliveryDate) {
              this.order = res.data.resultData as OrderHistoryViewModel;
              this.orderLoaded = true
              store.dispatch("checkout/refreshCart", {
                id: null,
                accessKey: this.accessKey || undefined
              });

              if(!this.$route.query.loaded || (!this.$route.query.loaded && this.$route.query.balance == 'none')) {
                this.trackPurchase(this.order)
                this.facebookPurchaseEvent({value: this.order.orderTotal, currency: this.order.currency.code})

                let newQueryParams:any = {
                  ...this.$route.query,
                  loaded: true,
                };
                this.$router.replace({ params: { lang: this.$route.params.lang }, query: newQueryParams });
              }

              this.loading = false;
            } else {
              if(this.tryCount <= 2) {
                this.tryCount += 1;
                setTimeout(() => {
                  this.loadInitialData();
                }, (1000 * this.tryCount));
              } else {
                this.orderLoaded = true
                this.loading = false;
              }
            }
          }
        })
        .catch((error) => {
          console.log(error)
          this.orderLoaded = true
          this.loading = false;
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage });
          });
        });
    }
  }
}
