
import { Options, Vue } from "vue-class-component";

import { store } from "@/store";
import router from "@/router";
import { Authentication } from "@/network/api";
import { ApplicationError } from "@/api-client/api";
import { Form, Field, ErrorMessage } from "vee-validate";
import Spinner from "@/components/spinner/Spinner.vue";

@Options({
  components: { Form, Field, ErrorMessage, Spinner },
})
export default class EmailVerificationPage extends Vue {
  email = "";
  resendEmailAddress = '';
  code = "";
  done = false;
  loading = false

  errors: Array<string> | null = null;

  created() {
    let queries = this.$route.query as any;
    if (queries.code != null) {
      this.code = unescape(queries.code) as string;
    }

    if(queries.email) {
      this.email = queries.email
    }

    // var params = router.currentRoute.value.params as any;
    if (queries.sent == 'success') {
      this.done = true;
    }
  }

  async resendEmail() {
    this.errors = null;

    if (!this.done) {
    this.loading = true
    await Authentication.identityAuthenticationResendEmailConfirmationPost(this.resendEmailAddress)
      .then((res) => {
        if (res.data.succeeded) {
          console.log("success resend", res);
          // this.$notify({ type: "success", text: 'Verification Email Resent.', ignoreDuplicates: true, duration: -1 });
          this.done = true;
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false;
        console.log({error})
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
          });
        
      });
    } else {
      this.done = false;
      this.loading = false
    }
  }

  async verifyEmail() {
    this.errors = null;
    this.loading = true
    await Authentication.identityAuthenticationConfirmEmailAddressPost(
      this.email,
      this.code
    )
      .then((res) => {
        if (res.data.succeeded) {
          console.log("success verify", res);
          this.$notify({ type: "success", text: 'Verification Successful.', ignoreDuplicates: true, duration: -1 });
          this.$router.push({name: 'Login', params: {lang: this.$route.params.lang}, query: {email: this.email}})
        }
        this.loading = false
      })
      .catch((error) => {
        console.log(error)
        debugger
        this.loading = false;
        if(error.response.status == 400) {
          this.done = false
          this.code = ''
          this.$router.replace({name: 'EmailVerification', params: { lang: this.$route.params.lang }})
          debugger
          this.$notify({ type: "error", text: 'Token expired. Please enter your email address and resend your verification email.', ignoreDuplicates: true, duration: -1 });
        } else {
          debugger
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
        }
      });
  }
}
