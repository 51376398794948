import { AuthenticationResult } from "@/api-client";
import { Authentication } from "@/network/api";
import { Store as VuexStore, Module, GetterTree, MutationTree } from "vuex";
import { RootState, store } from "..";
import { notify } from "@kyvg/vue3-notification";
import router from "@/router";

// interface TokenResponse {}

export enum SocialProviders {
  Google,
  Facebook,
  Apple,
}

export enum MutationTypes {
  SetToken = "SET_Auth_Token",
}

export type AuthState = {
  token: AuthenticationResult | null;
  isLoggedIn: boolean;
};

export const state: AuthState = {
  token: null, // use getter stright out of localstore or cookies
  isLoggedIn: getTokenState() != null,
};

type Getters = {
  token(state: AuthState, rootState: RootState): AuthenticationResult | null;
  isLoggedIn(state: AuthState): boolean;
};

function getTokenState(_state?: AuthState) {
  if (_state && _state!.token != null) {
    return _state!.token;
  }
  const vuex = localStorage.getItem("vuex");
  const store = JSON.parse(vuex!);
  if (store == null) {
    return null;
  }
  if (store["auth"] == null) {
    return null;
  }
  const tkn = store["auth"]["token"];
  if (tkn != null) {
    if (_state) {
      _state!.token = tkn;
    }
    return tkn;
  }
  return null;
  //TODO check state, if not found check store, if found check expiration date and if expired call refresh token & return token 
}

export const getters: GetterTree<AuthState, RootState> & Getters = {
  token(state) {
    return getTokenState(state);
  },
  isLoggedIn(state) {
    return state.isLoggedIn; // state.isLoggedIn;
  },
};

type Mutations = {
  [MutationTypes.SetToken](state: AuthState, token: any): void;
};

export const mutations: MutationTree<AuthState> & Mutations = {
  [MutationTypes.SetToken](state: AuthState, token: any) {
    const vuex = localStorage.getItem("vuex");
    let store = JSON.parse(vuex!);
    if (!store) {
      store = {};
    }
    if (!store["auth"]) {
      store["auth"] = {};
    }
    store["auth"]["token"] = token;
    localStorage.setItem("vuex", JSON.stringify({ ...store }));
    state.token = { ...token };
    // debugger
    if (token) {
      state.isLoggedIn = true;
    } else {
      state.isLoggedIn = false;
    }
  },
};

let _refreshingToken: Promise<void> | null = null;

async function completeAuth(res: any, context: any, resolve: any, reject: any) {
  if (res.status == 200) {
    const cartId = store.getters["checkout/cartId"];
    context.commit(MutationTypes.SetToken, res.data.resultData);
    await store.dispatch("user/getProfile", null, { root: true });
    if (!cartId) {
      const accessKey = router.currentRoute.value.query.accessKey;
      await store.dispatch("checkout/getCart", {
        accessKey: accessKey || undefined
      });
    }
    resolve();
  } else {
    reject(res.data);
  }
}

export const AuthModule: Module<AuthState, RootState> = {
  state,
  getters,
  mutations,
  actions: {
    login: async (context, params) => {
      const anonymousSavedDesigns = store.getters["user/anonymousSavedDesigns"]; 
      return new Promise<void>((resolve, reject) => {
        Authentication.identityAuthenticationLoginPost({
          email: params.email!,
          password: params.password!,
        })
          .then(async (res) => {
            // debugger
            await completeAuth(res, context, resolve, reject);
            // debugger
            if (res.status == 200) {
              const cartId = store.getters["checkout/cartId"];
              context.commit(MutationTypes.SetToken, res.data.resultData);              
              // await store.dispatch("user/getProfile", null, { root: true });
              await store.dispatch("location/getDefaultSelection", null, { root: true });
              // debugger
              if (!params.isQuote && !cartId) {
                const accessKey = router.currentRoute.value.query.accessKey;
                await store.dispatch("checkout/getCart", {
                  accessKey: accessKey || undefined
                });
              }

              if(anonymousSavedDesigns?.length) {
                store.dispatch("user/allocateAnonymousSavedDesigns", anonymousSavedDesigns);
              } 

              resolve();
            } else {
              reject(res.data);
            }
          })
          .catch((error) => {
            console.log("LOGIN FAILED", error);
            const errors = error.response.data.errors;
            errors.forEach((error: any) => {
              notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
            });
            console.error("LOGIN FAILED", error);
            reject(error);
          });
      });
    },
    logout: (context, params) => {
      // See NavBar.vue for "authed pages" that redirect on logout
      return new Promise<void>((resolve, reject) => {
        context.commit(MutationTypes.SetToken, null);
        const accessKey = router.currentRoute.value.query.accessKey;
        store.dispatch("checkout/refreshCart", {
          id: null,
          accessKey: accessKey
        });
        store.dispatch("user/clearProfile", null, { root: true }).then(() => {
          resolve();
        });
      });
    },
    refresh: (context, params) => {
      const cartId = store.getters["checkout/cartId"];
      return (_refreshingToken ??= new Promise<void>((resolve, reject) => {
        const refreshToken = (state.token! as any)["refreshToken"];
        if(refreshToken) {
          Authentication.identityAuthenticationRefreshTokenPost({
            refreshToken: refreshToken,
          })
            .then(async (res) => {
              if(res.data.succeeded) {
                context.commit(MutationTypes.SetToken, res.data.resultData);
                await store.dispatch("user/getProfile", null, { root: true });
                
                if(cartId) {
                  const accessKey = router.currentRoute.value.query.accessKey;
                  store.dispatch("checkout/refreshCart", {
                    id: cartId,
                    accessKey: accessKey
                  });
                }
                resolve();
              } else {
                reject(res.data)
              }
            })
            .catch((error) => {
            debugger
              console.log("REFRESH TOKEN FAILED", error);
              if(error.response.data.resultFailureType === 'Authorization') {
                const path = window.location.pathname;
                router.push({name: 'Login', params: {lang: router.currentRoute.value.params.lang}, query: { returnUrl: path }})
              } 
              // else {
              // const errors = error.response.data.errors;
              // errors.forEach((error: any) => {
              //   notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });             
              // });
              // }
              reject(error);
            });
        } else {
          debugger
          reject()
        }
      }).finally(() => (_refreshingToken = null)));
    },
    socialAuth: (context, params) => {
      return new Promise<void>((resolve, reject) => {
        switch (params.provider as SocialProviders) {
          case SocialProviders.Google:
            Authentication.identityAuthenticationLoginGoogleLoginGooglePost({ token: params.token })
              .then(async (res) => {
                await completeAuth(res, context, resolve, reject);
              })
              .catch((error) => {
                console.log("SOCIAL AUTH", error);
                const errors = error.response.data.errors;
                errors.forEach((error: any) => {
                  notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
                });
                reject(error);
              });
            break;
          case SocialProviders.Facebook:
            Authentication.identityAuthenticationLoginFacebookLoginFacebookPost({ token: params.token })
              .then(async (res) => {
                await completeAuth(res, context, resolve, reject);
              })
              .catch((error) => {
                console.log("REFRESH TOKEN FAILED", error);
                const errors = error.response.data.errors;
                errors.forEach((error: any) => {
                  notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
                });
                reject(error);
              });
            break;
          case SocialProviders.Apple:
            break;
        }
      })
    },
    register: (context, params) => {
      return new Promise<void>((resolve, reject) => {
        Authentication.identityAuthenticationRegisterPost({
          displayName: params.username,
          email: params.email!,
          password: params.password!,
          googleRecaptchaResponse: params.googleRecaptchaResponse,
        })
          .then((res) => {
            if (res.status == 200) {
              resolve();
            } else {
              reject(res.data);
            }
          })
          .catch((error) => {
            console.log("REGISTER FAILED", error);
            const errors = error.response.data.errors;
            errors.forEach((error: any) => {
              notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
            });
            reject(error);
          });
      });
    },
    resetPasswordComplete: (context, params) => {
      return new Promise<void>((resolve, reject) => {
        Authentication.identityAuthenticationResetForgotPasswordPost(params.email, params.token, params.password)
          .then((res) => {
            if (res.status == 200) {
              resolve();
            } else {
              reject(res.data);
            }
          })
          .catch((error) => {
            console.log("RESET PASSWORD FAILED", error);
            const errors = error.response.data.errors;
            errors.forEach((error: any) => {
              notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
            });
            reject(error);
          });
      });
    },
    forgotPassword: (context, params) => {
      return new Promise<void>((resolve, reject) => {
        Authentication.identityAuthenticationForgotPasswordPost(params.email!)
          .then((res) => {
            if (res.status == 200) {
              resolve();
            } else {
              reject(res.data);
            }
          })
          .catch((error) => {
            console.log("FORGOT PASSWORD FAILED", error);
            const errors = error.response.data.errors;
            errors.forEach((error: any) => {
              notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
            });
            reject(error);
          });
      });
    },
  },
  namespaced: true,
};
