
import { mixins, Options, Vue } from "vue-class-component";
import OrderListTile from "@/components/profile/order-history/OrderListTile.vue";
import OrderDetails from "@/components/profile/order-history/OrderDetails.vue";
import OrderInvoiceList from "@/components/profile/order-history/OrderInvoiceList.vue";
import OrderStoreRecipients from "@/components/profile/order-history/OrderStoreRecipients.vue";
import Datepicker from "@vuepic/vue-datepicker";
// import '@vuepic/vue-datepicker/dist/main.css'
import "@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss";
import { OrderHistoryViewModel, OrderHistoryViewModelPaginatedListApplicationResultResultData, OrderPublicStatus } from "@/api-client";
import { Orders } from "@/network/api";
import Spinner from "@/components/spinner/Spinner.vue";
import { FormatDate, FormatEnum } from "@/mixins/utilities";

@Options({
  components: { OrderListTile, Datepicker, OrderDetails, OrderInvoiceList, Spinner, OrderStoreRecipients },
})
export default class OrderHistory extends mixins(FormatDate, FormatEnum) {
  date: any = null;
  selectedDates: any = {
    start: undefined,
    end: undefined,
  };
  showDetails = false;
  showInvoice = false;
  showRecipients = false;
  selectedOrder: OrderHistoryViewModel | null = null;
  selectedStatus: any = undefined;
  user: any = null;
  statusFilters: OrderPublicStatus[] = ["Processing", "Dispatched", "Cancelled", "Quote", "InProduction", "PreparingToDispatch"];
  ordersPages: OrderHistoryViewModelPaginatedListApplicationResultResultData = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 0,
    totalPages: 0,
    pageSize: 0,
  };
  pageIndex = 1;
  orders: Array<OrderHistoryViewModel> = [];
  loading = false;
  loadingAll = false;
  showFindOrder = false; 

  created() {
    this.orders = [];
    this.loadInitialData().then(async () => {
      if(this.$route.query.order || this.$route.query.invoicelist || this.$route.query.invoice) {
        await this.loadOrderDetail(this.$route.query.order as string || this.$route.query.invoicelist as string)
        .then(() => {
          if(this.$route.query.order) {
            if(this.$route.query.recipients) {
              this.showRecipients = true;
            } else if (this.selectedOrder?.id) {
              this.showDetails = true;
            }
          } else {
            this.showInvoice = true;
          } 
        })
      } 
    });

    this.$watch("date", () => {
      if (this.date) {
        this.selectedDates = {
          start: new Date(this.date![0]),
          end: new Date(this.date![1]),
        };
      } else {
        this.selectedDates = {
          start: undefined,
          end: undefined,
        };
      }
      this.orders = []
      this.loadInitialData();
    });
  }

  mounted() {
    this.scroll();
  }


  isScrolledIntoView(el: any) {
    let rect = el.getBoundingClientRect() as DOMRect;
    let elemTop = rect.top;
    let elemBottom = rect.bottom;
    let isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
  }

  scroll() {
    window.onscroll = () => {
      let allRefs = this.$refs as any;
      let scrolledTo = allRefs.bottom;

      if (scrolledTo && this.isScrolledIntoView(scrolledTo)) {
        this.loadMoreOrders();
      }
    };
  }

  loadMoreOrders() {
    if ((this.loading === false) && (this.ordersPages.hasNextPage === true)) {
      this.loading = true;
      this.pageIndex += 1;

      this.loadInitialData(this.pageIndex);
    }
  }

  format() {
    return this.formatDate(this.date[0]) + ' - ' +  this.formatDate(this.date[1]);
  }

  clearFilters() {
    this.date = null;
    this.selectedDates = {
      start: undefined,
      end: undefined,
    };
    this.selectedStatus = undefined;
    this.pageIndex = 1;
    this.loadInitialData()
  }

  closeRecipients() {
    this.date = null;
    this.selectedDates = {
      start: undefined,
      end: undefined,
    };
    this.selectedStatus = undefined;
    this.$router.push({ name: "Profile", params: {lang: this.$route.params.lang}, query: { section: "order-history", order: this.selectedOrder?.id || undefined } });
    this.showDetails = true;
    this.showInvoice = false;
    this.showRecipients = false;
  }

  closeOrderExtras() {
    this.date = null;
    this.selectedDates = {
      start: undefined,
      end: undefined,
    };
    this.selectedOrder = null;
    this.selectedStatus = undefined;
    this.$router.push({ name: "Profile", params: {lang: this.$route.params.lang}, query: { section: "order-history" } });
    this.showDetails = false;
    this.showInvoice = false;
    this.showRecipients = false;
  }
  
  

  getOrderDetails(order: OrderHistoryViewModel) {
    this.selectedOrder = order;
    this.showDetails = true;
    this.$router.replace({ name: "Profile", params: { lang: this.$route.params.lang }, query: { section: "order-history" , order: this.selectedOrder.id } });
  }

  getInvoiceDetails(order: OrderHistoryViewModel) {
    this.selectedOrder = order;
    this.showInvoice = true;
    this.$router.replace({ name: "Profile", params: { lang: this.$route.params.lang }, query: { section: "order-history" , invoicelist: this.selectedOrder.id } });
  }

  getRecipients(){
    this.$router.replace({name: 'Profile', params: { lang: this.$route.params.lang }, query: {section: 'order-history', order: this.selectedOrder?.id || undefined, recipients: 'all'}})
    this.showRecipients = true;
    this.showDetails = false;
    this.showInvoice = false;
  }

  handleStatusChange() {
    this.orders = []
    this.loadInitialData()
  }

  async loadOrderDetail(id: string) {
    if (id) {
      this.loading = true;
      await Orders.ordersGetOrderOrderIdGet(id)
        .then((res) => {
          if (res.data.succeeded) {
            this.selectedOrder = res.data.resultData as any
          } else {
            this.selectedOrder = null;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage });
          });
          this.selectedOrder = null;
        });
    }
  }

  async loadInitialData(page = this.pageIndex) {
    this.loading = true;
    await Orders.ordersGetOrdersGet(page, 5, this.selectedDates.start, this.selectedDates.end, this.selectedStatus)
      .then((res) => {
        if (res.data.succeeded) {
          this.ordersPages = res.data.resultData as OrderHistoryViewModelPaginatedListApplicationResultResultData;
          this.ordersPages.items.forEach((item:any) => {
          //this is for lazy loading
          this.orders.push(item);
        });
        }
        this.loading = false;
      })
      .catch((error) => {
console.log(error)
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }

  beforeUnmount() {
    this.orders = [];
  }
}
