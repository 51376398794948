import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "profile-container container-fluid w-100 m-0 p-0" }
const _hoisted_2 = { class: "profile-content-container col-12 d-flex flex-column flex-sm-row" }
const _hoisted_3 = { class: "profile-control-container d-flex col-12 col-sm-3 col-md-4 col-lg-3" }
const _hoisted_4 = { class: "d-none d-sm-flex bg-secondary-light h-100 col-sm-12 d-flex flex-sm-column flex-wrap align-items-center py-2 py-sm-5" }
const _hoisted_5 = { class: "profile-control-mobile-container flex-centered d-flex d-sm-none bg-secondary-light col-12" }
const _hoisted_6 = { class: "col-11" }
const _hoisted_7 = ["value", "selected"]
const _hoisted_8 = { class: "d-flex col-12 col-sm-9 col-md-8 col-lg-9 justify-content-center justify-content-sm-start" }
const _hoisted_9 = { class: "profile-section-details-container col-11 col-sm-12 pt-4 pb-0 py-sm-5" }
const _hoisted_10 = { class: "col-12 flex-centered mb-4 mt-5 logout-button d-flex d-sm-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_HeadingBanner = _resolveComponent("HeadingBanner")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ProfileDetails = _resolveComponent("ProfileDetails")!
  const _component_AddressBook = _resolveComponent("AddressBook")!
  const _component_OrderHistory = _resolveComponent("OrderHistory")!
  const _component_CustomStores = _resolveComponent("CustomStores")!
  const _component_RecentlyViewedProducts = _resolveComponent("RecentlyViewedProducts")!
  const _component_SavedDesigns = _resolveComponent("SavedDesigns")!
  const _component_ResetPassword = _resolveComponent("ResetPassword")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Breadcrumbs, {
      basicPage: ['Profile'],
      basicRoute: [_ctx.formatRoute],
      routeName: _ctx.formatSectionName(_ctx.selectedSection)
    }, null, 8, ["basicRoute", "routeName"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_HeadingBanner, {
        short: true,
        bannerSrc: '/img/profile/profile-image-thin-s.jpg',
        title: 'My Profile'
      }, null, 8, ["bannerSrc"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.profileSections, (section, index) => {
              return (_openBlock(), _createBlock(_component_router_link, {
                class: _normalizeClass(["ms-2 ms-sm-0 mb-2 mb-sm-0 section-button col-sm-10 col-lg-8 btn-md-secondary flex-centered text-center text-md-start", { 'mt-sm-4': index !== 0, 'selected-section': _ctx.selectedSection === section.slug }]),
                key: index,
                onClick: ($event: any) => (_ctx.topAnchor(), _ctx.selectedSection = section.slug),
                to: { name: 'Profile', params: {lang: _ctx.$route.params.lang}, query: { section: section.slug } }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(section.name), 1)
                ]),
                _: 2
              }, 1032, ["onClick", "class", "to"]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _withDirectives(_createElementVNode("select", {
                class: "col-12 form-select",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedSection) = $event))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.profileSections, (section, index) => {
                  return (_openBlock(), _createElementBlock("option", {
                    class: "col-12",
                    key: index,
                    value: section.slug,
                    selected: _ctx.selectedSection === section.slug
                  }, _toDisplayString(section.name), 9, _hoisted_7))
                }), 128))
              ], 512), [
                [_vModelSelect, _ctx.selectedSection]
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            (_ctx.selectedSection === 'profile-details')
              ? (_openBlock(), _createBlock(_component_ProfileDetails, {
                  key: 0,
                  onLoading: _cache[1] || (_cache[1] = (x) => {_ctx.loading = x}),
                  loading: _ctx.loading,
                  user: _ctx.userProfile,
                  addresses: _ctx.addresses
                }, null, 8, ["loading", "user", "addresses"]))
              : _createCommentVNode("", true),
            (_ctx.selectedSection === 'address-book')
              ? (_openBlock(), _createBlock(_component_AddressBook, {
                  key: 1,
                  onLoading: _cache[2] || (_cache[2] = (x) => {_ctx.loading = x}),
                  loading: _ctx.loading,
                  addresses: _ctx.addresses,
                  onReload: _ctx.loadAllAddresses
                }, null, 8, ["loading", "addresses", "onReload"]))
              : _createCommentVNode("", true),
            (_ctx.selectedSection === 'order-history')
              ? (_openBlock(), _createBlock(_component_OrderHistory, { key: 2 }))
              : _createCommentVNode("", true),
            (_ctx.selectedSection === 'online-stores')
              ? (_openBlock(), _createBlock(_component_CustomStores, { key: 3 }))
              : _createCommentVNode("", true),
            (_ctx.selectedSection === 'recently-viewed-products')
              ? (_openBlock(), _createBlock(_component_RecentlyViewedProducts, { key: 4 }))
              : _createCommentVNode("", true),
            (_ctx.selectedSection === 'saved-designs')
              ? (_openBlock(), _createBlock(_component_SavedDesigns, { key: 5 }))
              : _createCommentVNode("", true),
            (_ctx.selectedSection === 'reset-password')
              ? (_openBlock(), _createBlock(_component_ResetPassword, { key: 6 }))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("p", {
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args))),
          class: "h-xs-secondary text-uppercase m-0 py-2 px-3 bg-secondary-light"
        }, "Logout")
      ])
    ])
  ]))
}