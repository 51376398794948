
import { mixins, Options, Vue } from "vue-class-component";
import OrderProductTile from "@/components/profile/order-history/OrderProductTile.vue";
import { CalculatePrice, FormatDate, ThousandSeparator, ScrollTop, FormatEnum } from "@/mixins/utilities";
import { OrderHistoryViewModel, OrderPublicStatus } from "@/api-client";
import { Orders } from "@/network/api";
import Spinner from "@/components/spinner/Spinner.vue";

@Options({
  components: { OrderProductTile, Spinner },
  props: {
    order: { default: '' },
    pageName: { default: 'Profile' }
  },
  emits: ['close', 'recipients']
})
export default class OrderDetails extends mixins(CalculatePrice, ThousandSeparator, FormatDate, ScrollTop, FormatEnum) {
  orderId:any = '';
  order: OrderHistoryViewModel = {
    id: "",
    referenceNumber: "",
    orderPublicStatusId: OrderPublicStatus.Processing,
    orderPlacementDate: "",
    updatedDeliveryDate: "",
    expectedDeliveryDate: "",
    showOrderPackApprovalLink: false,
    showAssetResupplyLink: false,
    showOutstandingPaymentLink: false,
    marketingOptIn: false,
    additionalCostsConvertedTotal: 0,
    currency: {
      id: "de7c7489-fcc5-40d4-ad7c-f9de27a97f18",
      name: "Pound Sterling",
      code: "GBP",
      symbol: "£",
      pricingMultiplier: 1,
      decimalPlaces: 2,
    },
    orderTotal: 0,
    orderTotalIncludingVoucher: 0,
    orderItems: [],
    deliveryTotal: 0,
    adhocTotal: 0,
    orderInvoices: [],
    customerContacts: [],
    paymentsConvertedTotal: 0,
    paymentsTotal: 0
  };
  orderClone: OrderHistoryViewModel = {
    id: "",
    referenceNumber: "",
    orderPublicStatusId: OrderPublicStatus.Processing,
    orderPlacementDate: "",
    expectedDeliveryDate: "",
    showOrderPackApprovalLink: false,
    showAssetResupplyLink: false,
    showOutstandingPaymentLink: false,
    marketingOptIn: false,
    additionalCostsConvertedTotal: 0,
    currency: {
      id: "de7c7489-fcc5-40d4-ad7c-f9de27a97f18",
      name: "Pound Sterling",
      code: "GBP",
      symbol: "£",
      pricingMultiplier: 1,
      decimalPlaces: 2,
    },
    orderTotal: 0,
    orderTotalIncludingVoucher: 0,
    orderItems: [],
    deliveryTotal: 0,
    adhocTotal: 0,
    orderInvoices: [],
    customerContacts: [],
    paymentsConvertedTotal: 0,
    paymentsTotal: 0
  };
  loading = false;
  pageName = 'Profile'

  created() {
    if (this.order) {
      this.orderClone = {...this.order};
    } else if (!this.order && this.$route.query.order) {
      this.orderId = this.$route.query.order;
      this.loadInitialData();
    }
  }

  get awaitingAction() {
    if(this.orderClone.showOrderPackApprovalLink) {
      return 'order pack approval';
    } else if(this.orderClone.showAssetResupplyLink) {
      return 'asset resupply';
    } else if(this.orderClone.showOutstandingPaymentLink) {
      return 'outstanding payment';
    }
    return false;
  }

  goToAwaitingAction() {
    let routeData:any = null

    if(this.orderClone.showOrderPackApprovalLink) {
      routeData = `${process.env.VUE_APP_ROOT_ADMIN}/orderpack/approval/${this.orderClone.orderPackIdAwaitingApproval}`
    } else if(this.orderClone.showAssetResupplyLink) {
      routeData = this.$router.resolve({ name: 'ResupplyAssets', params: { id: this.orderClone.id, lang: this.$route.params.lang } });
    } else if(this.orderClone.showOutstandingPaymentLink) {
      routeData = this.$router.resolve({ name: 'OrderPayments', params: { id: this.orderClone.id, lang: this.$route.params.lang } });
    }

    if(typeof routeData === 'string') {
      window.open(routeData, '_blank');
    } else if (routeData) {
      window.open(`${routeData.href}`, '_blank');
    }
  }

  async optOutOrderMarketing() {
    this.orderClone.marketingOptIn = false
    this.loading = true

    await Orders.ordersUpdateMarketingFlagPut(this.orderClone.id, this.orderClone.marketingOptIn)
      .then((res) => {
        this.order.marketingOptIn = this.orderClone.marketingOptIn
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        let errors = error.response.data.errors
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage })
        });
        console.log(error)
      });
  }

  topAnchor() {
    this.scrollTop(`.${this.pageName.toLowerCase()}-content-container`, false);
  }

  viewRecipients(productId: string | null = null) {
    this.$emit('recipients')
  }

  get orderCurrency() {
    if (this.order.id) {
      return this.order.currency;
    }
    return null;
  }

  loadInitialData() {
    if (this.orderId) {
      this.loading = true;
      Orders.ordersGetOrderOrderIdGet(this.orderId)
        .then((res) => {
          if (res.data.succeeded) {
            this.orderClone = res.data.resultData as any
          }
          this.loading = false;
        })
        .catch((error) => {
console.log(error)
          this.loading = false
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage });
          });
          console.log(error);
        });
    }
  }
}
